import { get, isUndefined, merge } from 'lodash';
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { COMPANY_API } from '../../../api/company';
import PageFiltersRenderer from '../../../components/shared-ui/PageFiltersRenderer';
import ComplianceStatusTable from '../../../components/Table/ComplianceStatusTable';
import Toast from '../../../components/Toast';
import PageContainer from '../../../containers/PageContainer';
import { FAVR_COMPLIANCE_STATUS_OPTIONS } from '../../../enum/FavrCompliances';
import withAuthentication from '../../../hocs/withAuthentication';
import useCompanyFavrComplianceUsersQuery from '../../../hooks/queries/useCompanyFavrComplianceUsersQuery';
import usePaginatedFiltersQuery from '../../../hooks/queries/usePaginatedFiltersQuery';
import useTableSort from '../../../hooks/useTableSort';
import { parseQueryParams } from '../../../utils/queryParams';
import { selectStoreCurrentCompany } from '../../../utils/storeSelectors';

const COMMON_RESPONSE_FILTER_SETTINGS = {
  xs: 24,
  md: 12,
  lg: 6,
};

const ComplianceStatusView = props => {
  const { t, history, location } = props;

  const queryParams = parseQueryParams(location.search);

  const currentCompany = useSelector(selectStoreCurrentCompany);

  const [filters, setFilters] = useState({
    searchTerm: '',
    status: 'all',
    userId: isUndefined(queryParams?.userId) ? null : queryParams?.userId,
  });

  const usersQuery = useCompanyFavrComplianceUsersQuery(t);
  const { stringTableSort, handleTableSort } = useTableSort({ firstName: 1, lastName: 1 });

  const {
    query: complianceUserDataQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery(
    {
      placeholderData: { count: 0, documents: [] },
      staleTime: 1000 * 60 * 10, // 10 minutes,
      queryKey: [
        'getAllUsersFavrComplianceData',
        currentCompany._id,
        ...Object.values(filters),
        stringTableSort,
      ],
      queryFn: () =>
        COMPANY_API.getAllUsersFavrComplianceData(
          currentCompany._id,
          filters,
          paginationConfig.current,
          paginationConfig.pageSize,
          stringTableSort,
        ),
      onError: error =>
        handleApiErrors(error.response, () => {
          return Toast({
            type: 'error',
            message: t('getAllUsersFavrComplianceDataError'),
          });
        }),
    },
    {
      resetPageQueryKey: Object.values(filters),
      useQueryParams: true,
      initialPage: queryParams?.page,
      initialPageSize: queryParams?.pageSize,
    },
    'totalCount',
  );

  const handleFiltersChange = updatedFilters => {
    setFilters(filter => merge({ ...filter }, updatedFilters));
  };

  return (
    <PageContainer title={t('userProgramCompliance')}>
      <PageFiltersRenderer
        t={t}
        history={history}
        loading={usersQuery.isFetching || complianceUserDataQuery.isFetching}
        onFiltersChange={handleFiltersChange}
        search={{ defaultValue: filters.searchTerm }}
        filters={[
          {
            componentType: 'select',
            label: t('user'),
            name: 'userId',
            defaultValue: filters.userId,
            options: [{ label: t('All'), value: null }, ...usersQuery.data],
            responsive: COMMON_RESPONSE_FILTER_SETTINGS,
          },
          {
            componentType: 'select',
            label: t('status'),
            name: 'status',
            defaultValue: filters.status,
            options: [{ label: t('All'), value: 'all' }, ...FAVR_COMPLIANCE_STATUS_OPTIONS()],
            responsive: COMMON_RESPONSE_FILTER_SETTINGS,
          },
        ]}
      />

      <ComplianceStatusTable
        t={t}
        asyncSort
        loading={complianceUserDataQuery.isFetching}
        dataSource={get(complianceUserDataQuery.data, 'documents')}
        onChange={({ current }, filters, sorters) => {
          handlePageChange(current);
          handleTableSort(sorters?.columnKey, sorters?.order);
        }}
        pagination={{
          pageSize: paginationConfig.pageSize,
          total: paginationConfig.total,
          current: paginationConfig.current,
          onShowSizeChange: handlePageSizeChange,
        }}
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(ComplianceStatusView));
