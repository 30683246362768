import React from 'react';

import { RATE_FORM_WIZARD_STEPS, RATE_TYPES } from '../../enum/Rates';
import useRateForm from '../../hooks/useRateForm';
import { getFinalCompletedStep } from '../../utils/rates';
import TabsContainer from '../TabsContainer';

const RateWizardTabNavigation = props => {
  const { t, formInstance, wizardInstance, displayCPMCompliance } = props;
  const { rateWizard, goToStep } = useRateForm(formInstance, wizardInstance);

  return (
    <TabsContainer
      activeKey={rateWizard.currentStep}
      onChange={goToStep}
      items={[
        {
          label: t('rateInformation'),
          key: RATE_FORM_WIZARD_STEPS.RATE_FORM,
        },
        ...(rateWizard.rateValues.rateType === RATE_TYPES.FAVR
          ? [
              {
                label: t('selectStandardVehicle'),
                key: RATE_FORM_WIZARD_STEPS.SELECT_STANDARD_VEHICLE,
                disabled:
                  getFinalCompletedStep(rateWizard.rateValues) <
                  RATE_FORM_WIZARD_STEPS.SELECT_STANDARD_VEHICLE,
              },
              {
                label: t('compliance'),
                key: RATE_FORM_WIZARD_STEPS.COMPLIANCE,
                disabled:
                  getFinalCompletedStep(rateWizard.rateValues) < RATE_FORM_WIZARD_STEPS.COMPLIANCE,
              },
              {
                label: t('review'),
                key: RATE_FORM_WIZARD_STEPS.REVIEW,
                disabled:
                  getFinalCompletedStep(rateWizard.rateValues) < RATE_FORM_WIZARD_STEPS.REVIEW,
              },
            ]
          : displayCPMCompliance
          ? [
              {
                label: t('compliance'),
                key: RATE_FORM_WIZARD_STEPS.CPM_COMPLIANCE,
                disabled:
                  getFinalCompletedStep(rateWizard.rateValues) <
                  RATE_FORM_WIZARD_STEPS.CPM_COMPLIANCE,
              },
            ]
          : []),
      ]}
    />
  );
};

export default RateWizardTabNavigation;
