import { get, merge, pick } from 'lodash';
import moment from 'moment-timezone';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { handleApiErrors } from '../../api/axiosInstance';
import { createRate, fetchRateDetails, updateRate } from '../../api/products';
import { INSURANCE_COVERAGES_FIELDS } from '../../components/RateFormWizard/rate-form-wizard.contants';
import Toast from '../../components/Toast';
import { APPROVAL_CONFIG_VALUES, RATE_TYPES, SEMI_AUTO_VALUES } from '../../enum/Rates';
import i18n from '../../i18n';
import { getMomentDateWithoutTimezone } from '../../utils/datetime';
import {
  formatInsuranceComplianceInitialValues,
  getApprovalSettingValue,
  isGovernmentFlatRate,
} from '../../utils/rates';
import { selectStoreCountryByCode, selectStorePlanType } from '../../utils/storeSelectors';
import * as actions from '../actions/rateWizard';

function* saveRate() {
  const rateWizard = yield select(store => store.rateWizard);
  const { isUpdateWizard, rateValues, originalRateDetails } = rateWizard;
  const formValues = {
    ...pick(rateValues, [
      'name',
      'approvalConfig',
      'approvalSetting',
      'country',
      'defaultRate',
      'groups',
      'rateType',
    ]),
    effectiveDate: moment(rateValues.effectiveDate).format('YYYY-MM-DD'),
  };

  const { isCustomOrPremium } = yield select(selectStorePlanType);
  const cpmInsuranceValidation = isCustomOrPremium
    ? {
        insuranceCompliance: !!rateValues.insuranceValidation,
        insuranceComplianceCheckFrequency: rateValues.insuranceComplianceCheckFrequency,
        annualComplianceSubmission: rateValues.annualComplianceSubmission,
        gracePeriod: rateValues.gracePeriod,
      }
    : undefined;

  if (isGovernmentFlatRate(rateValues.rateType, rateValues.country)) {
    formValues.govtRate = parseFloat(rateValues.rateAmount);
    formValues.compliance = cpmInsuranceValidation;
  } else if (rateValues.rateType === RATE_TYPES.FLAT) {
    formValues.flatRate = parseFloat(rateValues.rateAmount);
    formValues.compliance = cpmInsuranceValidation;
  } else if (rateValues.rateType === RATE_TYPES.TIERED) {
    formValues.bands = rateValues.rateBands;
    formValues.compliance = cpmInsuranceValidation;
  } else if (rateValues.rateType === RATE_TYPES.FAVR) {
    const favrVehicles = yield select(store => store.favrVehicles);
    const fullVehicleModel = favrVehicles.byYearData[rateValues.vehicleYear]
      ? favrVehicles.byYearData[rateValues.vehicleYear].modelsByMake[rateValues.vehicleMake].find(
          model => model.model === rateValues.vehicleModel,
        )
      : {};

    formValues.favrSettings = {
      customSettings: rateValues.customSettings,
      annualBusinessMiles: rateValues.annualBusinessMiles,
      businessUse: rateValues.businessUse,
      retentionYears: rateValues.retentionYears,
      insuranceCompliance: !!rateValues.insuranceCompliance,
      insuranceComplianceCheckFrequency: rateValues.insuranceComplianceCheckFrequency,
      vehicleCompliance: !!rateValues.vehicleCompliance,
      vehicleComplianceCheckFrequency: rateValues.vehicleComplianceCheckFrequency,
      gracePeriod: rateValues.vehicleCompliance ? rateValues.gracePeriod : undefined,
      annualComplianceSubmission: rateValues.annualComplianceSubmission,
      proratedFixedAmount: get(rateValues, 'proratedFixedAmount', false),
      prorationMethod: rateValues.prorationMethod,
      fixedReceiptAutoApproval:
        rateValues?.approvalConfig === APPROVAL_CONFIG_VALUES.MANUAL
          ? false
          : !!rateValues.fixedReceiptAutoApproval,
      complianceMinimumAnnualBusinessMiles: rateValues.complianceMinimumAnnualBusinessMiles || 5000,
      standardVehicle: {
        class: fullVehicleModel?.vehicle_class || rateValues.vehicleClass,
        make: rateValues.vehicleMake,
        model: rateValues.vehicleModel,
        year: rateValues.vehicleYear,
        fuel: rateValues.vehicleFuel,
      },
    };
  }

  try {
    yield put(actions.emitStartWizardSubmission());

    if (isUpdateWizard) {
      yield call(updateRate, originalRateDetails._id, formValues);
      if (formValues.defaultRate) {
        window.queryClient.invalidateQueries({ exact: false, queryKey: ['fetchCompanyRates'] });
      }
      Toast({
        type: 'open',
        message: i18n.t('rateUpdateSuccess'),
      });
    } else {
      yield call(createRate, formValues);
      Toast({
        type: 'open',
        message: i18n.t('rateCreateSuccess'),
      });
    }

    yield put(actions.emitUpdateWizardDirty(false));
    yield put(actions.emitCompleteWizardSubmission());
  } catch (error) {
    handleApiErrors(error.response, () => {
      Toast({
        type: 'error',
        message: i18n.t('rateSaveError'),
      });
    });
    yield put(actions.emitFailedWizardSubmission());
  }
}

function* setupWizardDependencies(rateId) {
  const companyGroups = yield select(store => store.group.groupList);
  const groupsWithRates = {};

  companyGroups.forEach(group => {
    if (group?.productId?._id && group?.productId?._id !== rateId) {
      groupsWithRates[group.name] = group.productId;
    }
  });

  return { groupsWithRates };
}

function* setupRateValues(rateId) {
  let originalRateDetails = {};
  let rateValues = {
    name: '',
    groups: [],
    country: 'US',
    effectiveDate: moment(),
    defaultRate: false,
    approvalConfig: '',
    vehicleYear: 2022,
    rateBands: [{ bandStart: 0, bandEnd: 99999 }],
    gracePeriod: 60,
  };

  if (rateId) {
    const rateDetails = yield call(fetchRateDetails, rateId);
    originalRateDetails = rateDetails;

    const country = selectStoreCountryByCode(rateDetails.country);
    const rateType = rateDetails?.rates?.rateType;
    const flatRate = rateDetails?.rates?.flatRate;
    const govtRate = rateDetails?.rates?.govtRate;

    rateValues.country = country ? rateDetails.country : 'US';

    rateValues.rateType = rateType;
    rateValues.rateAmount = rateType === RATE_TYPES.FLAT ? flatRate : govtRate;

    rateValues.rateBands =
      Array.isArray(rateDetails.rates?.bands) && !!rateDetails.rates.bands.length
        ? rateDetails.rates?.bands
        : [{ bandStart: 0, bandEnd: 99999 }];

    if (Array.isArray(rateDetails.rates?.bands)) {
      rateDetails.rates.bands.forEach((band, i) => {
        rateValues['bandStart-' + i] = band.bandStart;
        rateValues['bandEnd-' + i] = band.bandEnd;
        rateValues['bandRate-' + i] = band.bandRate;
      });
    }

    rateValues.groups = rateDetails.groups.map(group => group._id);

    rateValues.effectiveDate = rateDetails.effectiveDate
      ? getMomentDateWithoutTimezone(rateDetails.effectiveDate)
      : moment();

    rateValues.approvalConfigValue =
      rateDetails.approvalSetting && rateDetails.approvalSetting.limit
        ? rateDetails.approvalSetting.limit
        : '';

    if (rateDetails.approvalConfig === APPROVAL_CONFIG_VALUES.SEMI) {
      rateValues[SEMI_AUTO_VALUES.DISTANCE] = getApprovalSettingValue(
        SEMI_AUTO_VALUES.DISTANCE,
        rateDetails.approvalSetting,
      );
      rateValues[SEMI_AUTO_VALUES.GPS] = getApprovalSettingValue(
        SEMI_AUTO_VALUES.GPS,
        rateDetails.approvalSetting,
      );
      rateValues[SEMI_AUTO_VALUES.REIMBURSEMENT] = getApprovalSettingValue(
        SEMI_AUTO_VALUES.REIMBURSEMENT,
        rateDetails.approvalSetting,
      );
    }

    const { isCustomOrPremium } = yield select(selectStorePlanType);
    if (rateType !== RATE_TYPES.FAVR && isCustomOrPremium) {
      const compliance = rateDetails?.compliance;
      rateValues.insuranceValidation = compliance?.insuranceCompliance;
      rateValues.insuranceComplianceCheckFrequency = compliance?.insuranceComplianceCheckFrequency;
      rateValues.annualComplianceSubmission = compliance?.annualComplianceSubmission;
      rateValues.gracePeriod = compliance?.gracePeriod;
    }

    if (rateType === RATE_TYPES.FAVR && rateDetails?.favrSettings) {
      const favrSettings = rateDetails.favrSettings;
      rateValues.customSettings = favrSettings.customSettings;
      rateValues.annualBusinessMiles = favrSettings.annualBusinessMiles;
      rateValues.businessUse = favrSettings.businessUse;
      rateValues.retentionYears = favrSettings.retentionYears;
      rateValues.insuranceCompliance = favrSettings.insuranceCompliance;
      rateValues.insuranceComplianceCheckFrequency = favrSettings.insuranceComplianceCheckFrequency;
      rateValues.vehicleCompliance = favrSettings.vehicleCompliance;
      rateValues.vehicleComplianceCheckFrequency = favrSettings.vehicleComplianceCheckFrequency;
      rateValues.gracePeriod = favrSettings.gracePeriod || 60;
      rateValues.vehicleClass = favrSettings?.standardVehicle?.class;
      rateValues.vehicleMake = favrSettings?.standardVehicle?.make;
      rateValues.vehicleModel = favrSettings?.standardVehicle?.model;
      rateValues.vehicleYear = favrSettings?.standardVehicle?.year;
      rateValues.vehicleFuel = favrSettings?.standardVehicle?.fuel;
      rateValues.annualComplianceSubmission = favrSettings?.annualComplianceSubmission;
      rateValues.proratedFixedAmount = favrSettings?.proratedFixedAmount;
      rateValues.prorationMethod = favrSettings?.prorationMethod;
      rateValues.fixedReceiptAutoApproval = !!favrSettings?.fixedReceiptAutoApproval;
      rateValues.complianceMinimumAnnualBusinessMiles =
        favrSettings?.complianceMinimumAnnualBusinessMiles || 5000;
      INSURANCE_COVERAGES_FIELDS.forEach(field => {
        rateValues[field] = favrSettings?.insuranceCoverages?.[field];
      });
    }

    merge(
      rateValues,
      pick(rateDetails, ['name', 'status', 'defaultRate', 'approvalConfig', 'approvalSetting']),
      formatInsuranceComplianceInitialValues(
        rateDetails.insuranceComplianceType,
        rateDetails.insuranceComplianceInsuranceSettings,
        rateDetails.insuranceComplianceVehicleSettings,
      ),
    );
  }

  return {
    originalRateDetails,
    rateValues,
    initialValues: rateValues,
  };
}

function* setupRateWizard(action) {
  try {
    const results = yield all([
      setupWizardDependencies(action.data.rateId),
      setupRateValues(action.data.rateId),
    ]);

    const mergedResults = merge(
      {
        isUpdateWizard: !!action.data.rateId,
        isSettingUpWizard: false,
        instance: action.data.instance,
      },
      ...results,
    );

    yield put(actions.emitUpdateWizardStore(mergedResults));
  } catch (error) {
    handleApiErrors(error.response, () => {
      Toast({
        type: 'error',
        message: i18n.t('rateSetupError'),
      });
    });
  }
}

export const rateWizardSaga = [
  takeLatest(actions.SETUP_RATE_WIZARD, setupRateWizard),
  takeLatest(actions.RATE_WIZARD_SAVE_RATE, saveRate),
];
