/* eslint-disable no-useless-escape */
import Toast from '../components/Toast';
import i18n from '../i18n';

export function isValidPassword(pw) {
  let result = 0;
  if (!pw) {
    result = 4;
  } else if (pw.length < 8) {
    result = 1;
  } else {
    let containNumber = false;
    let containCapical = false;
    let containUpperCase = false;
    for (let i = 0; i < pw.length; i++) {
      const c = pw.charAt(i);
      if (!isNaN(c)) {
        containNumber = true;
      }
      let characterPattern = /[~`@!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
      if (characterPattern.test(c)) {
        containCapical = true;
      }
      let upperCasePattern = /^[A-Z]*$/;
      let result = upperCasePattern.test(c);
      if (result) {
        containUpperCase = true;
      }
    }
    if (!containNumber) {
      result = 2;
    }
    if (!containCapical) {
      result = 3;
    }
    if (!containUpperCase) {
      result = 5;
    }
  }

  return result;
}

export function validateFields(field, value, value1 = '') {
  let result = {};
  switch (field) {
    case 'password':
      switch (isValidPassword(value)) {
        case 0:
          result = {
            validateStatus: 'success',
            errorMsg: null,
          };
          break;
        case 1:
          result = {
            validateStatus: 'error',
            errorMsg: i18n.t('invalidPassword'),
          };
          break;
        case 2:
          result = {
            validateStatus: 'error',
            errorMsg: i18n.t('invalidPassword'),
          };
          break;
        case 3:
          result = {
            validateStatus: 'error',
            errorMsg: i18n.t('invalidPassword'),
          };
          break;
        case 4:
          result = {
            validateStatus: 'error',
            errorMsg: i18n.t('pleaseEnterYourPassword'),
          };
          break;
        case 5:
          result = {
            validateStatus: 'error',
            errorMsg: i18n.t('invalidPassword'),
          };
          break;

        default:
          break;
      }
      break;
    case 'confirmPassword':
      if (value) {
        const isValid = isValidPassword(value);
        if (isValid === 0 && value === value1) {
          result = {
            validateStatus: 'success',
            errorMsg: null,
          };
        } else {
          result = {
            validateStatus: 'error',
            errorMsg: i18n.t('passwordDoNoMatchRenter'),
          };
        }
      } else {
        result = {
          validateStatus: 'error',
          errorMsg: i18n.t('passwordDoNoMatchRenter'),
        };
      }
      break;
    case 'acceptedTerm':
      if (value) {
        result = {
          validateStatus: 'success',
          errorMsg: null,
        };
      } else {
        result = {
          validateStatus: 'error',
          errorMsg: i18n.t('acceptTermsAndConditions'),
        };
      }
      break;
    default:
  }

  return result;
}

export function isAmericaPhoneNumber(number) {
  const americaPhonePattern = /^\D*([2-9]\d{2})(\D*)([2-9]\d{2})(\D*)(\d{4})\D*$/;
  return americaPhonePattern.test(number);
}

const MAX_IMAGE_FILE_SIZE = 2000000;

/**
 * Validates the provided image file to check if it matches the required dimensions
 *
 * @param {File} imageFile Image file
 * @param {Function} onSuccess Callback if image is valid. It takes the file and a base64 version of the image as params
 */
export const validateProfileImageSize = (imageFile, onSuccess = (file, base64) => undefined) => {
  if (imageFile) {
    let reader = new FileReader();
    reader.onload = e => {
      if (e.total > MAX_IMAGE_FILE_SIZE) {
        reader.abort();
        const fileSize = MAX_IMAGE_FILE_SIZE / 1048576;
        Toast({
          type: 'error',
          message: i18n.t('profileImgMaxFileSizeMessage', { fileSize }),
        });
        return;
      }

      let image = new Image();
      image.onload = () => {
        if (image.width > 180 || image.height > 180) {
          Toast({
            type: 'warning',
            duration: 10, // seconds
            message: i18n.t('profileImgBigDimensionMessage', image),
          });
          if (typeof onSuccess === 'function') {
            onSuccess(imageFile, reader.result);
          }
        } else if (image.width === 180 && image.height === 180 && typeof onSuccess === 'function') {
          onSuccess(imageFile, reader.result);
        } else {
          Toast({
            type: 'error',
            duration: 10, // seconds
            message: i18n.t('profileImgSmallDimensionMessage', image),
          });
        }
      };

      image.src = reader.result;
    };
    reader.readAsDataURL(imageFile);
  }
};

/**
 * Validates the provided image file to check if it matches the required dimensions
 *
 * @param {File} imageFile Image file
 * @param {Function} onSuccess Callback if image is valid. It takes the file and a base64 version of the image as params
 */
export const validateCompanyLogoImageSize = (
  imageFile,
  onSuccess = () => undefined,
  onError = () => undefined,
) => {
  const maxFileSize = 200000;

  if (imageFile) {
    let reader = new FileReader();
    reader.onload = event => {
      if (event.total > maxFileSize) {
        reader.abort();
        const fileSize = maxFileSize / 1000;
        Toast({ type: 'error', message: i18n.t('companyLogoMaxFileSizeMessage', { fileSize }) });
        return onError(i18n.t('companyLogoMaxFileSizeMessage', { fileSize }));
      }

      let image = new Image();
      image.onload = () => {
        if (image.width > 320 || image.height > 640) {
          Toast({
            type: 'error',
            duration: 10, // seconds
            message: i18n.t('companyLogoBigDimensionMessage', image),
          });
          onError(i18n.t('companyLogoBigDimensionMessage', image));
        } else if (image.width < 160 || image.height < 320) {
          Toast({
            type: 'error',
            duration: 10, // seconds
            message: i18n.t('companyLogoSmallDimensionMessage', image),
          });
          onError(i18n.t('companyLogoSmallDimensionMessage', image));
        } else if (typeof onSuccess === 'function') {
          onSuccess(imageFile, reader.result);
        }
      };

      image.src = reader.result;
    };

    reader.readAsDataURL(imageFile);
  }
};

export const USER_NAME_VALIDATION_REGEXP = new RegExp(
  /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/,
);

export const EMAIL_SPECIAL_CHAR_VALIDATION_REGEXP = /[ `!#$%^&*()=\[\]{};':"\|,<>\/?]/;
