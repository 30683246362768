import { Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepWizard from 'react-step-wizard';

import { handleApiErrors } from '../../api/axiosInstance';
import { createGroup } from '../../api/group';
import { INTERNAL_LINKS } from '../../enum';
import { RATE_FORM_WIZARD_STEPS, RATE_TYPES } from '../../enum/Rates';
import useAdminsAndManagersUsersLists from '../../hooks/useAdminsAndManagersUsersLists';
import useModal from '../../hooks/useModal';
import useRateForm from '../../hooks/useRateForm';
import { emitFetchCountryRate } from '../../stores/actions/common';
import { updateGroupList } from '../../stores/actions/group';
import * as rateWizardActions from '../../stores/actions/rateWizard';
import {
  selectStoreCurrentCompany,
  selectStorePlanType,
  useStoreSelector,
} from '../../utils/storeSelectors';
import UsersAssociatedToThisRateView from '../../views/productsettings/productDetails/UsersAssociatedToThisRateView';
import Box from '../Box';
import GroupCreationModal from '../Modal/GroupCreationModal';
import ProductForm from '../ProductForm/ProductForm';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import Text from '../Text';
import Toast from '../Toast';
import UnloadWarningModal from '../UnloadWarningModal';
import CPMInsuranceValidation from './CPMInsuranceValidation';
import InsuranceAndDriverCompliance from './InsuranceAndDriverCompliance';
import RateWizardTabNavigation from './RateWizardTabNavigation';
import SelectedVehicleSettingsChangedModal from './SelectedVehicleSettingsChangedModal';
import SelectStandarVehicleSection from './SelectStandarVehicleSection';

const RateFormWizard = props => {
  const { t, match, location, history } = props;

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [wizardInstance, setWizardInstance] = useState();
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const companyGroups = useStoreSelector('group.groupList', []);
  const [groupCreationVisible, openGroupCreationModal, closeGroupCreationModal] = useModal();
  const [
    isConfirmingVehicleChanges,
    openConfirmVehicleChangesModal,
    closeConfirmVehicleChangesModal,
  ] = useModal();

  const { rateWizard, goToStep, currentDefaultRate } = useRateForm(form, wizardInstance);

  const currentCompany = useSelector(selectStoreCurrentCompany);
  const { isCustomOrPremium } = useStoreSelector(selectStorePlanType);
  const { groupManagersLists } = useAdminsAndManagersUsersLists();

  const goToRatesPage = () => history.push(INTERNAL_LINKS.RATES);

  const handleCreateGroup = useCallback(
    async formData => {
      setIsCreatingGroup(true);

      try {
        const result = await createGroup(
          formData.name,
          currentCompany._id,
          formData.managerID,
          currentDefaultRate._id,
          [],
          formData.paymentScheduleId,
          formData.mileageEntryMode,
        );
        if (!result) throw new Error(t('unableToCreateGroup'));

        dispatch(
          updateGroupList([
            ...companyGroups,
            {
              ...result,
              groupManagerList: groupManagersLists.find(u => u._id === formData.managerID),
            },
          ]),
        );

        closeGroupCreationModal();
      } catch (e) {
        handleApiErrors(e.response);
      }

      setIsCreatingGroup(false);
    },
    [
      t,
      dispatch,
      closeGroupCreationModal,
      companyGroups,
      groupManagersLists,
      currentCompany._id,
      currentDefaultRate,
    ],
  );

  useEffect(() => {
    if (rateWizard.rateValues.country) {
      dispatch(emitFetchCountryRate(rateWizard.rateValues.country));
    }
    // eslint-disable-next-line
  }, [rateWizard.rateValues.country]);

  useEffect(() => {
    if (wizardInstance && !rateWizard.isSettingUpWizard) {
      form.setFieldsValue(rateWizard.rateValues);
    }
    // eslint-disable-next-line
  }, [form, rateWizard.isSettingUpWizard, wizardInstance]);

  useEffect(() => {
    return () => {
      dispatch(rateWizardActions.emitResetRateWizardValues());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (rateWizard.isSubmitted) {
      history.push(INTERNAL_LINKS.RATES);
    }
  }, [history, rateWizard.isSubmitted]);

  return (
    <React.Fragment>
      <RateWizardTabNavigation
        t={t}
        formInstace={form}
        wizardInstance={wizardInstance}
        displayCPMCompliance={isCustomOrPremium}
      />

      <Box
        style={{
          maxWidth: rateWizard.currentStep < RATE_FORM_WIZARD_STEPS.REVIEW ? 700 : undefined,
          overflowX: 'hidden',
        }}
      >
        <StepWizard
          isLazyMount
          initialStep={rateWizard.currentStep}
          instance={setWizardInstance}
          transitions={{
            enterRight: '',
            enterLeft: '',
            exitRight: '',
            exitLeft: '',
          }}
        >
          <ProductForm
            t={t}
            isEdit={!!match.params.id}
            formInstance={form}
            onCancel={goToRatesPage}
            onCreateGroup={openGroupCreationModal}
            isCustomOrPremiumPlan={isCustomOrPremium}
            onFinish={() =>
              form.validateFields().then(() => {
                if (rateWizard.rateValues.rateType === RATE_TYPES.FAVR) {
                  goToStep(RATE_FORM_WIZARD_STEPS.SELECT_STANDARD_VEHICLE);
                } else if (isCustomOrPremium) {
                  goToStep(RATE_FORM_WIZARD_STEPS.CPM_COMPLIANCE);
                } else {
                  dispatch(rateWizardActions.emitSaveRate());
                }
              })
            }
          />

          {isCustomOrPremium &&
            rateWizard.rateValues.rateType &&
            rateWizard.rateValues.rateType !== RATE_TYPES.FAVR && (
              <CPMInsuranceValidation
                t={t}
                formInstance={form}
                onBackClick={() => goToStep(RATE_FORM_WIZARD_STEPS.RATE_FORM)}
                onNextClick={() =>
                  form.validateFields().then(() => {
                    dispatch(rateWizardActions.emitSaveRate());
                  })
                }
              />
            )}

          {rateWizard.rateValues.rateType === RATE_TYPES.FAVR && (
            <SelectStandarVehicleSection
              t={t}
              formInstance={form}
              onBackClick={() => goToStep(RATE_FORM_WIZARD_STEPS.RATE_FORM)}
              onNextClick={() =>
                form.validateFields().then(() => goToStep(RATE_FORM_WIZARD_STEPS.COMPLIANCE))
              }
            />
          )}

          {rateWizard.rateValues.rateType === RATE_TYPES.FAVR && (
            <InsuranceAndDriverCompliance
              t={t}
              formInstance={form}
              onBackClick={() => goToStep(RATE_FORM_WIZARD_STEPS.SELECT_STANDARD_VEHICLE)}
              onNextClick={() =>
                form.validateFields().then(() => goToStep(RATE_FORM_WIZARD_STEPS.REVIEW))
              }
            />
          )}

          <div>
            <UsersAssociatedToThisRateView isWizard match={match} />

            <SubmitCancelButtonGroup
              cancelText={t('Back')}
              submitText={t('Save')}
              loading={rateWizard.isSubmitting}
              onCancel={() => goToStep(RATE_FORM_WIZARD_STEPS.COMPLIANCE)}
              onSubmit={() =>
                form
                  .validateFields()
                  .then(() => {
                    if (rateWizard.isSelectedVehicleDirty) {
                      openConfirmVehicleChangesModal();
                    } else {
                      dispatch(rateWizardActions.emitSaveRate());
                    }
                  })
                  .catch(error => {
                    Toast({
                      type: 'error',
                      message: t('oneOrMoreFieldsAreInvalid'),
                    });
                  })
              }
            />
          </div>
        </StepWizard>

        <GroupCreationModal
          t={t}
          isCreatingGroup={isCreatingGroup}
          visible={groupCreationVisible}
          onGroupCreate={handleCreateGroup}
          onCancel={closeGroupCreationModal}
          groupManagerList={groupManagersLists}
        />

        <SelectedVehicleSettingsChangedModal
          t={t}
          visible={isConfirmingVehicleChanges}
          onCancel={closeConfirmVehicleChangesModal}
          onSubmit={() => {
            dispatch(rateWizardActions.emitSaveRate());
            closeConfirmVehicleChangesModal();
          }}
        />

        <UnloadWarningModal
          when={rateWizard.isDirty}
          history={history}
          location={location}
          message={
            rateWizard.isUpdateWizard ? (
              <div>
                <Text>{t('unsavedChanges')}</Text>
                <Text>{t('sureYouWantToLeaveThisPage')}</Text>
              </div>
            ) : (
              <div>
                <Text>{t('abandonRateWizardCreateProcessConfirmation')}</Text>
              </div>
            )
          }
        />
      </Box>
    </React.Fragment>
  );
};

export default RateFormWizard;
