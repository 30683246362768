import { LineChartOutlined } from '@ant-design/icons';
import { Col, Drawer, Row, Tooltip } from 'antd';
import { find, get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, ConfirmModal } from '../../../../components';
import MetabaseMonthlyMileageChart from '../../../../components/MetabaseMonthlyMileageChart';
import FullScreenTripModal from '../../../../components/Modal/FullScreenTripModal';
import ReceiptDeleteConfirmationModal from '../../../../components/Modal/ReceiptDeleteConfirmationModal';
import ReceiptStatusHistoryModal from '../../../../components/Modal/ReceiptStatusHistoryModal';
import ReimbursementReceiptConfirmationWithMoreInfoTripModal from '../../../../components/Modal/ReimbursementReceiptConfirmationWithMoreInfoTripModal';
import SubmitReimbursementsForApprovalConfirmation from '../../../../components/Modal/SubmitReimbursementsForApprovalConfirmation';
import TripsCommentsModal from '../../../../components/Modal/TripsCommentsModal';
import OneReceiptPerPeriodSummary from '../../../../components/Receipts/OneReceiptPerPeriodSummary';
import ReceiptSummaryCard from '../../../../components/Receipts/ReceiptSummaryCard';
import FixedReceiptDetailsTable from '../../../../components/Table/FixedReceiptDetailsTable';
import TripsTableByPeriod from '../../../../components/Table/TripsTable/TripsTableByPeriod';
import StatusTag from '../../../../components/Tag/StatusTag';
import Text from '../../../../components/Text';
import LinkText from '../../../../components/Text/LinkText';
import { RECEIPT_TYPES, STATUS_LIST } from '../../../../enum';
import useRetryMassTransactionMutation from '../../../../hooks/mutations/useRetryMassTransactionMutation';
import useTripsComments from '../../../../hooks/useTripsComments';
import { momentFormat, setModalFullscreenWidth } from '../../../../utils/common';
import { getMomentDateWithoutTimezone } from '../../../../utils/datetime';
import {
  hasCompanyAdminRole,
  hasCompanyManagerOrAdminRole,
  hasUserRole,
} from '../../../../utils/roles';
import {
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
} from '../../../../utils/storeSelectors';
import ReceiptFooterAdminActionBoxContent from '../../../receipt/details/ReceiptFooterAdminActionBoxContent';
import ReceiptFooterUserActionBoxContent from '../../../receipt/details/ReceiptFooterUserActionBoxContent';
import { canDeleteReceipt, canUpdateReceiptStatus } from '../../../receipt/receipt-permissions';
import { canAddCommentOnTrip } from '../../../trips/trips-permissions';
import ReimbursementDetailsPageContainer from '../../ReimbursementDetailsPageContainer';
import classNames from './styles.module.scss';
import useReceiptDetailsState from './useReceiptDetailsState';

const ReimbursementsDetailsOnPeriodView = props => {
  const { t, history, match } = props;

  const {
    // Booleans
    isLoading,
    isApprovingReceipt,
    isRejectingTrip,
    isRejectingReceipt,
    isTripDetailsVisible,
    // isAdjustmentHistVisible,
    isStatusHistoryVisible,
    isRequestingMoreInfo,
    isProcessingMoreInfoRequest,
    isResubmittingReceiptsForApproval,
    isApprovalConfirmationVisible,
    isRejectConfirmationVisible,
    isTripRejectConfirmationVisible,
    isApprovalWithMoreInfoVisible,
    isResubmitConfirmationVisible,
    isDeleteReceiptModalVisible,

    // General variables
    receipt,
    receiptTrips,
    receiptTripsWithMoreInfo,
    // receiptAdjustments,
    tripMapConfig,
    selectedTripsMoreInfo,

    // Handlers & Togglers
    updateReceiptData,
    handleViewTripMapFullScreen,
    handleTripMapClose,
    handleMoreInfoRequest,
    handleApprove,
    handleDeny,
    handleTripDeny,
    handleResubmitForApproval,
    // toggleAdjustmentHistoryModal,
    toggleStatusHistoryModal,
    toggleRequestMoreInfo,
    openApprovalConfirmation,
    closeApprovalConfirmation,
    openRejectionConfirmation,
    closeRejectionConfirmation,
    openTripRejectionConfirmation,
    closeTripRejectionConfirmation,
    openResubmitConfirmation,
    closeResubmitConfirmation,
    updateSelectedTripsForMoreInfo,
    updateTripLatestComment,
    openDeleteReceiptModal,
    closeDeleteReceiptModal,
    deleteReceiptMutation,
  } = useReceiptDetailsState({ t, history, receiptID: match.params.id });

  const {
    tripCommentsQuery,
    commentsPaginationConfig,
    tripIdToViewComments,
    isAddingComment,
    isTripCommentsModalVisible,
    openTripCommentsModal,
    closeTripCommentsModal,
    addCommentToTrip,
    handleCommentsPageChange,
  } = useTripsComments({ onAddTripComment: updateTripLatestComment });

  const retryMassTransactionMutation = useRetryMassTransactionMutation(t, {
    onSuccess: () => {
      updateReceiptData({ paymentStatus: STATUS_LIST().Status.PROCESSING });
    },
  });

  const currentCompany = useSelector(selectStoreCurrentCompany);
  const authUserProfile = useSelector(selectStoreCurrentAuthUser);
  const companySettings = useSelector(store => store.common.currentCompany.companySettingId);
  const { groupList } = useSelector(store => store.group);
  const [isMonthlyChartVisible, setIsMonthlyChartVisible] = useState(false);

  const CAN_APPROVE_TRIPS =
    hasCompanyManagerOrAdminRole(authUserProfile) &&
    !!companySettings?.settings?.approveReceipt &&
    ![STATUS_LIST().Status.PAID, STATUS_LIST().Status.DENIED].includes(receipt?.status) &&
    !isRequestingMoreInfo;

  const paymentPeriodString = useMemo(() => {
    let period = [];
    const paymentPeriodStart = get(receipt, 'period.paymentPeriodStart');
    const paymentPeriodEnd = get(receipt, 'period.paymentPeriodEnd');

    if (receipt?.type === RECEIPT_TYPES.FIXED_TYPE) {
      period = [
        getMomentDateWithoutTimezone(paymentPeriodStart).format('MMM DD, YYYY'),
        getMomentDateWithoutTimezone(paymentPeriodEnd).format('MMM DD, YYYY'),
      ];
    } else {
      period = [
        momentFormat(paymentPeriodStart, 'MMM DD, YYYY'),
        momentFormat(paymentPeriodEnd, 'MMM DD, YYYY'),
      ];
    }

    return period.join(' - ');
  }, [receipt]);

  return (
    <div className={classNames.reimbursementDetailsWrapper}>
      <ReimbursementDetailsPageContainer
        t={t}
        style={{
          paddingBottom:
            canUpdateReceiptStatus(authUserProfile, undefined, true) || isRequestingMoreInfo
              ? 45
              : undefined,
        }}
        loading={!receipt || isLoading}
        companySettings={companySettings}
        history={history}
        sideActionComponent={
          receipt &&
          hasCompanyAdminRole(authUserProfile) && (
            <Tooltip
              placement="left"
              enabled={!canDeleteReceipt(receipt)}
              title={t('onlyFixedReimbursementsCanBeDeleted')}
            >
              <Button disabled={!canDeleteReceipt(receipt)} onClick={openDeleteReceiptModal}>
                {t('Delete')}
              </Button>
            </Tooltip>
          )
        }
        breadcrumbs={[
          receipt?.period?.paymentPeriodStart && receipt?.period?.paymentPeriodEnd
            ? {
                label: (
                  <Row align="middle" gutter={10} style={{ display: 'inline-flex' }}>
                    <Col>{paymentPeriodString}</Col>

                    <Col>
                      <StatusTag
                        status={
                          receipt.status === STATUS_LIST().Status.PENDING
                            ? STATUS_LIST().Status.AWAITING_APPROVAL
                            : receipt.status
                        }
                      />
                    </Col>

                    {!isLoading &&
                      Array.isArray(receipt.statusHistory) &&
                      !!receipt.statusHistory.length && (
                        <Col>
                          <LinkText onClick={toggleStatusHistoryModal}>
                            {t('statusHistory')}
                          </LinkText>
                        </Col>
                      )}
                  </Row>
                ),
              }
            : undefined,
        ]}
      >
        <Row style={{ marginBottom: 60 }}>
          <Col flex={1}>
            {receipt && receipt.type === RECEIPT_TYPES.FIXED_TYPE && (
              <ReceiptSummaryCard
                t={t}
                loading={isLoading}
                receipt={receipt}
                onRetry={() =>
                  retryMassTransactionMutation.mutateAsync(receipt.dwollaCorrelationId._id)
                }
              />
            )}

            {receipt && receipt.type === RECEIPT_TYPES.VARIABLE_TYPE && (
              <OneReceiptPerPeriodSummary t={t} receipt={receipt} />
            )}
          </Col>
        </Row>

        <Row style={{ marginBottom: 20 }} justify="space-between" gutter={[16, 16]}>
          <Col flex={1}>
            {receipt && receipt.type === RECEIPT_TYPES.FIXED_TYPE && (
              <Text variant="h4">{t('fixedAmountDetails')}</Text>
            )}

            {receipt && receipt.type === RECEIPT_TYPES.VARIABLE_TYPE && (
              <Text variant="h4">{t('tripsDuringThisPeriod')}</Text>
            )}
          </Col>

          {companySettings?.settings?.mileageCap && hasCompanyManagerOrAdminRole(authUserProfile) && (
            <Col>
              <Button
                size="sm"
                type="secondary"
                onClick={() => setIsMonthlyChartVisible(true)}
                icon={<LineChartOutlined style={{ fontSize: '16px' }} />}
              >
                {t('viewHistoricalMileage')}
              </Button>
            </Col>
          )}
        </Row>

        <Row>
          <Col flex={1}>
            {receipt && receipt.type === RECEIPT_TYPES.FIXED_TYPE && (
              <FixedReceiptDetailsTable
                t={t}
                dataSource={[
                  {
                    _id: receipt._id,
                    date: receipt.created,
                    currency: receipt.currency,
                    paymentFor: receipt.period.paymentPeriodEnd,
                    fixedAmount: receipt.totalAmount,
                    favrInformations: receipt.favrInformations,
                  },
                ]}
              />
            )}

            {receipt && receipt.type === RECEIPT_TYPES.VARIABLE_TYPE && (
              <TripsTableByPeriod
                t={t}
                showSearchInput
                dataSource={receiptTrips}
                canRejectTrip={CAN_APPROVE_TRIPS}
                onTripReject={openTripRejectionConfirmation}
                isRejectingTrip={isRejectingReceipt}
                handleViewFullScreen={handleViewTripMapFullScreen}
                onViewMoreComments={openTripCommentsModal}
                rowSelection={
                  isRequestingMoreInfo
                    ? {
                        type: 'checkbox',
                        selectedRowKeys: selectedTripsMoreInfo,
                        getCheckboxProps: item => ({
                          disabled: item.status === STATUS_LIST().Status.MORE_INFO,
                        }),
                        onChange: selectedRowKeys => {
                          if (hasCompanyManagerOrAdminRole(authUserProfile)) {
                            updateSelectedTripsForMoreInfo(selectedRowKeys);
                          }
                        },
                      }
                    : undefined
                }
              />
            )}
          </Col>
        </Row>
      </ReimbursementDetailsPageContainer>

      <ReceiptStatusHistoryModal
        t={t}
        title={t('rbStatusHistory')}
        visible={isStatusHistoryVisible}
        statusList={receipt ? receipt.statusHistory : []}
        proration={receipt ? receipt.proration : undefined}
        paymentPeriodEnd={receipt ? get(receipt, 'period.paymentPeriodEnd') : undefined}
        onCancel={toggleStatusHistoryModal}
      />

      <FullScreenTripModal
        visible={isTripDetailsVisible}
        mapConfig={tripMapConfig}
        onCancel={handleTripMapClose}
      />

      <ConfirmModal
        loading={isRejectingReceipt}
        visible={isRejectConfirmationVisible}
        title={t('sureYouWantToRejectReimbursement')}
        message={t('rejectAllTripsAndSomeWithMoreInfoInReimbursement')}
        onOk={() => handleDeny(match.params.id)}
        onCancel={closeRejectionConfirmation}
        okText={t('yes')}
        cancelText={t('no')}
      />

      {receipt && hasCompanyManagerOrAdminRole(authUserProfile) && (
        <ConfirmModal
          loading={isApprovingReceipt}
          visible={isApprovalConfirmationVisible}
          title={t('sureYouWantToApproveTrips', { count: receiptTripsWithMoreInfo.length || 0 })}
          onOk={() => handleApprove(match.params.id)}
          onCancel={closeApprovalConfirmation}
          okText={t('yes')}
          cancelText={t('no')}
        />
      )}

      {receipt && hasCompanyManagerOrAdminRole(authUserProfile) && (
        <ConfirmModal
          loading={isRejectingTrip}
          visible={isTripRejectConfirmationVisible}
          title={t('sureYouWantToRejectTrips', { count: 1 })}
          message={
            receiptTripsWithMoreInfo.length > 0
              ? t('rejectAllTripsAndSomeWithMoreInfoInReimbursement')
              : undefined
          }
          onOk={handleTripDeny}
          onCancel={closeTripRejectionConfirmation}
          okText={t('yes')}
          cancelText={t('no')}
        />
      )}

      {receipt && hasCompanyManagerOrAdminRole(authUserProfile) && (
        <ReimbursementReceiptConfirmationWithMoreInfoTripModal
          t={t}
          type="reimbursement"
          visible={isApprovalWithMoreInfoVisible}
          tripsCount={receiptTripsWithMoreInfo.length}
          onCancel={closeApprovalConfirmation}
          isApprovingReceipt={isApprovingReceipt}
          onApproveAllTripsClick={() => handleApprove(match.params.id, 'approveAll')}
          onTripsAwaitingForApprovalClick={() => handleApprove(match.params.id, 'approvePending')}
        />
      )}

      {receipt && (
        <SubmitReimbursementsForApprovalConfirmation
          t={t}
          count={1}
          visible={isResubmitConfirmationVisible}
          isSubmitting={isResubmittingReceiptsForApproval}
          onCancel={closeResubmitConfirmation}
          onConfirm={() => handleResubmitForApproval(receipt?._id)}
        />
      )}

      <TripsCommentsModal
        t={t}
        visible={isTripCommentsModalVisible}
        onCancel={closeTripCommentsModal}
        loading={tripCommentsQuery.isFetching}
        comments={tripCommentsQuery.data.documents}
        paginationConfig={commentsPaginationConfig}
        onPageChange={handleCommentsPageChange}
        isAddingComment={isAddingComment}
        onAddComment={addCommentToTrip}
        canAddComment={canAddCommentOnTrip(
          tripIdToViewComments ? find(receiptTrips, { _id: tripIdToViewComments }) : undefined,
        )}
      />

      {receipt &&
        hasCompanyManagerOrAdminRole(authUserProfile) &&
        canUpdateReceiptStatus(authUserProfile, undefined, true) && (
          <ReceiptFooterAdminActionBoxContent
            // General
            t={t}
            isLoading={!receipt || isLoading || isRejectingReceipt || isApprovingReceipt}
            loggedInUser={authUserProfile}
            currentCompany={currentCompany}
            companyGroups={groupList}
            // Receipt
            receipt={receipt}
            onApprove={openApprovalConfirmation}
            onDeny={openRejectionConfirmation}
            onResubmitForApprovalFixed={openResubmitConfirmation}
            // More info
            isRequesting={isProcessingMoreInfoRequest}
            isRequestingMoreInfo={isRequestingMoreInfo}
            receiptTrips={receiptTrips}
            selectedTrips={selectedTripsMoreInfo}
            disableRequestMoreInfo={receiptTripsWithMoreInfo.length === receiptTrips.length}
            toggleRequestMoreInfo={toggleRequestMoreInfo}
            onSendToEmployee={handleMoreInfoRequest}
          />
        )}

      {receipt && hasUserRole(authUserProfile) && (
        <ReceiptFooterUserActionBoxContent
          // General
          t={t}
          isLoading={isLoading}
          loggedInUser={authUserProfile}
          // Receipt
          receipt={receipt}
          onResubmitForApprovalFixed={openResubmitConfirmation}
        />
      )}

      {hasCompanyAdminRole(authUserProfile) && (
        <ReceiptDeleteConfirmationModal
          t={t}
          isReimbursement
          visible={isDeleteReceiptModalVisible}
          loading={deleteReceiptMutation.isLoading}
          onOk={deleteReceiptMutation.mutateAsync}
          onCancel={closeDeleteReceiptModal}
        />
      )}

      {companySettings?.settings?.mileageCap && (
        <Drawer
          width={setModalFullscreenWidth(848)}
          placement="right"
          open={isMonthlyChartVisible}
          onClose={() => setIsMonthlyChartVisible(false)}
        >
          <MetabaseMonthlyMileageChart
            userId={get(receipt, 'submittedBy._id')}
            companyId={currentCompany._id}
          />
        </Drawer>
      )}
    </div>
  );
};

export default withNamespaces()(ReimbursementsDetailsOnPeriodView);
