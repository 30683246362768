import { STATUS_LIST } from '../enum';

/**
 * Get the latest adjustment from a list in a receipt
 *
 * @param {string} receipt Receipt to fetch
 */
export const getLatestAdjustments = receipt => {
  if (Array.isArray(receipt.adjustments) && !!receipt.adjustments.length) {
    const latestAdjustment = receipt.adjustments[Math.max(receipt.adjustments.length - 1, 0)];
    return latestAdjustment;
  }

  return undefined;
};

/**
 * Returns the original amount from a receipt eeven from adjustments
 *
 * @param {string} receipt
 */
export const getReceiptOriginalAmount = receipt => {
  if (Array.isArray(receipt.adjustments) && !!receipt.adjustments.length) {
    return receipt.adjustments[0].previousAmount;
  }

  return receipt.totalAmount;
};

export const findReceiptsWithTripsInMoreInfoStatus = (receipts = [], selectedReceiptsIDs = []) => {
  return receipts.filter(receipt => {
    const baseCondition =
      Array.isArray(receipt.trips) &&
      receipt.trips.filter(trip => trip.journeyStatus === STATUS_LIST().Status.MORE_INFO).length >
        0;

    if (selectedReceiptsIDs.length) {
      return selectedReceiptsIDs.includes(receipt._id) && baseCondition;
    }

    return baseCondition;
  });
};
