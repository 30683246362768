import { forEach, get, merge, pick } from 'lodash';
import queryString from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../api/axiosInstance';
import { COMPANY_API } from '../api/company';
import StripeAPI from '../api/stripe';
import { subscribeToPlan } from '../api/subscription';
import Toast from '../components/Toast';
import { STATUS_LIST } from '../enum';
import { PREFERRED_PAYMENT_METHODS } from '../enum/Billing';
import { saveCompany, updateCompanyPlan } from '../stores/actions/common';
import { selectStoreCurrentCompany } from '../utils/storeSelectors';
import useInfiniteStripeCardsQuery from './queries/useInfiniteStripeCardsQuery';
import useStripeCustomerQuery from './queries/useStripeCustomerQuery';
import useDidUpdateEffect from './useDidUpdateEffect';
import useModal from './useModal';

const _formatCreditCardFormValues = values => {
  const { expirationDate, ...creaditCardValues } = values;
  const currentYearStartingNumbers = new Date().getFullYear().toString().slice(0, 2);

  const [expMonth, expYear] = expirationDate.split(' / ');

  return {
    ...creaditCardValues,
    expMonth,
    expYear: currentYearStartingNumbers + '' + expYear,
    cardNumber: values.cardNumber.split(' ').join(''),
    isDefault: !!values.isDefault,
  };
};

const useStripeCreditCards = ({
  t,
  preferredPaymentMethod = PREFERRED_PAYMENT_METHODS.CREDIT_CARD,
  location,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const currentCompany = useSelector(selectStoreCurrentCompany);

  const [creditCardToEdit, setCreditCardToEdit] = useState();
  const [isCreditCardModalVisible, openCreditCardModal, closeCreditCardModal] = useModal();
  const [isDeleteCardModalOpen, openDeleteCardModal, closeDeleteCardModal] = useModal();

  const openEditCreditCardModal = useCallback(
    card => {
      setCreditCardToEdit(card);
      openCreditCardModal();
    },
    [openCreditCardModal],
  );

  const openDeleteCreditCardModal = useCallback(
    cardId => {
      setCreditCardToEdit(cardId);
      openDeleteCardModal();
    },
    [openDeleteCardModal],
  );

  const closeDeleteCreditCardModal = useCallback(() => {
    setCreditCardToEdit();
    closeDeleteCardModal();
  }, [closeDeleteCardModal]);

  const stripeCustomerQuery = useStripeCustomerQuery();

  const {
    isFetching: isFetchingStripeCards,
    hasNextPage: hasMoreStripeCards,
    data: stripeCardsQueryData,
    fetchNextPage: fetchMoreStripeCards,
    refetch: refetchStripeCards,
  } = useInfiniteStripeCardsQuery({
    enabled: preferredPaymentMethod === PREFERRED_PAYMENT_METHODS.CREDIT_CARD,
  });

  const stripeCards = useMemo(() => {
    let defaultCard;
    const otherCards = [];

    forEach(get(stripeCardsQueryData, 'pages'), ({ data }) => {
      forEach(data, card => {
        const defaultCardId = get(stripeCustomerQuery.data, 'defaultSource');

        if (defaultCardId === card.id && !!card.last4) {
          defaultCard = card;
          card.isDefault = true;
          otherCards.unshift(card);
        } else if (!!card.last4) {
          otherCards.push(card);
        }
      });
    });

    return { defaultCard, otherCards };
  }, [stripeCardsQueryData, stripeCustomerQuery]);

  const isFetchingStripeInformation = useMemo(() => {
    return isFetchingStripeCards || stripeCustomerQuery.isFetching;
  }, [isFetchingStripeCards, stripeCustomerQuery]);

  const updateCreditCardMutation = useMutation({
    mutationFn: async values => {
      return new StripeAPI().updateCard(creditCardToEdit.id, values);
    },
    onSuccess: () => {
      closeCreditCardModal();
      refetchStripeCards();
      Toast({
        type: 'open',
        message: t('cardUpdateSuccess'),
      });
    },
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('cardUpdateError'),
        });
      });
    },
  });

  const createCreditCardMutation = useMutation({
    mutationFn: async values => {
      return new StripeAPI().addCard(values);
    },
    onSuccess: async (newCard, values) => {
      if (values.isDefault) {
        queryClient.setQueryData(
          ['fetchCustomerInformation', currentCompany.stripeCustomerId],
          merge(stripeCustomerQuery.data, { defaultSource: newCard.id }),
        );
      }

      await updatePlanFromQuery();
      await refetchStripeCards();

      await COMPANY_API.getActivePlan(currentCompany._id, plan => {
        dispatch(updateCompanyPlan(plan));
        dispatch(saveCompany({ status: STATUS_LIST().Status.ACTIVE }));
      });

      let description = '';

      // if (cardData.upcomingInvoices) {
      //   let invoiceDate = result.data.upcomingInvoices.created;
      //   description += 'The card will be charged on ' + momentFormat(invoiceDate * 1000, 'll');
      // }

      Toast({
        type: 'success',
        message: t('cardAddSuccess'),
        description,
      });
      closeCreditCardModal();
    },
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('cardAddError'),
        });
      });
    },
  });

  const handleCreditCardSubmitMutation = useMutation({
    mutationFn: async formValues => {
      const params = _formatCreditCardFormValues(formValues);

      if (creditCardToEdit?.id) {
        await updateCreditCardMutation.mutateAsync(pick(params, ['expMonth', 'expYear', 'name']));
      } else {
        await createCreditCardMutation.mutateAsync(
          pick(params, ['cardNumber', 'isDefault', 'expMonth', 'expYear', 'name', 'cvc']),
        );
      }
    },
  });

  const setDefaultCardMutation = useMutation({
    mutationFn: cardId => new StripeAPI().seDefaultCard(cardId),
    onSuccess: cardId => {
      queryClient.setQueryData(
        ['fetchCustomerInformation', currentCompany.stripeCustomerId],
        merge(stripeCustomerQuery.data, { defaultSource: cardId }),
      );
      Toast({
        type: 'open',
        message: t('cardUpdateSuccess'),
      });
    },
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('cardUpdateError'),
        });
      });
    },
  });

  const deleteCardMutation = useMutation({
    mutationFn: () => new StripeAPI().deleteCard(creditCardToEdit),
    onSuccess: () => {
      closeDeleteCreditCardModal();
      refetchStripeCards();
      Toast({
        type: 'open',
        message: t('cardDeleteSuccess'),
      });
    },
    onError: error => {
      setCreditCardToEdit();
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('cardDeleteError'),
        });
      });
    },
  });

  const updatePlanFromQuery = async () => {
    const { plan } = queryString.parse(location.search);

    if (plan && plan !== 'undefined') {
      try {
        await subscribeToPlan(plan);

        Toast({
          type: 'success',
          message: t('planUpdateSuccess'),
        });
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('subscribeToPlanError'),
          });
        });
      }
    }
  };

  useDidUpdateEffect(() => {
    if (!isCreditCardModalVisible) {
      setCreditCardToEdit();
    }
  }, [isCreditCardModalVisible]);

  return {
    closeCreditCardModal,
    closeDeleteCreditCardModal,
    creditCardToEdit,
    deleteCardMutation,
    fetchMoreStripeCards,
    handleCreditCardSubmitMutation,
    hasMoreStripeCards,
    isCreditCardModalVisible,
    isDeleteCardModalOpen,
    isFetchingStripeInformation,
    openCreditCardModal,
    openDeleteCreditCardModal,
    openEditCreditCardModal,
    setDefaultCardMutation,
    stripeCards,
    stripeCustomerQuery,
  };
};

export default useStripeCreditCards;
