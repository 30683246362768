import { isNull, omit } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiErrors } from '../../../api/axiosInstance';
import { updateCompanyMileagePurpose } from '../../../api/company';
import { updateCompanySettings } from '../../../api/company-settings';
import Toast from '../../../components/Toast';
import useModal from '../../../hooks/useModal';
import { saveCompany, updateCompanySettingsStore } from '../../../stores/actions/common';
import { hasSystemAdminRole } from '../../../utils/roles';
import { REIMBURSEMENT_PURPOSE, TAX_RETURN_PURPOSE } from './company-settings-constants';
import useCommuteFormState from './useCommuteFormState';

const useCompanySettingsState = ({ t, form }) => {
  const dispatch = useDispatch();

  const [
    isCustomPlanConfirmModalVisible,
    openCustomPlanConfirmModal,
    closeCustomPlanConfirmModal,
  ] = useModal();
  const [
    isCommuteConfirmModalVisible,
    openCommuteConfirmModal,
    closeCommuteConfirmModal,
  ] = useModal();
  const [isDirty, setIsDirty] = useState(false);
  const [isCustomPlanDirty, setCustomPlanDirty] = useState(false);

  const authUser = useSelector(store => store.profile);
  const { currentCompany } = useSelector(store => store.common);
  const companySettings = currentCompany.companySettingId || {};
  const [planSettings, setPlanSettings] = useState(
    currentCompany.companySettingId?.customPlanId?._id
      ? { customPlanId: currentCompany.companySettingId.customPlanId._id }
      : {},
  );
  const [currentCompanySettings, setCurrentCompanySettings] = useState(
    currentCompany.companySettingId?.settings || {},
  );
  const [currentCompanySettingValues, setCurrentCompanySettingValues] = useState(
    currentCompany.companySettingId?.values || {},
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInitialPlanCustom, setIsInitialPlanCustom] = useState(!!planSettings?.customPlanId);

  const {
    commuteSettings: companyCommuteSettings,
    formattedCommuteSettings: formattedCompanyCommuteSettings,
    isCommuteSettingsDirty,
    handleCommuteSettingChange,
    handleResetCommuteDirty,

    isCommuteSettingSingleLocation,
    isCommuteSettingNotHaveOffice,
    isCommuteSettingCustom,
    isNoOfficeMaxDeduction,
    isNoOfficeIgnoreFirstAndLast,
    isHomeAddressMaxCommuteDeduction,
    commuteDistanceUnit,
  } = useCommuteFormState({
    initialCommuteSettings: currentCompany.companySettingId?.commute || {},
  });

  const handleActiveFeatureChange = (setting = {}) => {
    if (setting.hasOwnProperty('importLocationsMethod')) {
      if (setting.importLocationsMethod && !currentCompanySettingValues.importLocationsMethod) {
        handleSettingValueChange({ importLocationsMethod: 'csv' });
      }
    }
    if (setting?.customPricePlan) {
      setCustomPlanDirty(true);
    }

    setIsDirty(true);
    setCurrentCompanySettings(state => ({ ...state, ...setting }));
  };

  const handleSettingValueChange = (setting = {}) => {
    setIsDirty(true);
    form.setFieldsValue({ ...currentCompanySettingValues, ...setting });
    setCurrentCompanySettingValues({ ...currentCompanySettingValues, ...setting });

    if (setting.mileagePurpose === REIMBURSEMENT_PURPOSE) {
      handleActiveFeatureChange({
        tripApprovalWorkflow: true,
        approveReceipt: true,
        createReceipt: true,
      });
    } else if (setting.mileagePurpose === TAX_RETURN_PURPOSE) {
      handleActiveFeatureChange({
        tripApprovalWorkflow: false,
        approveReceipt: false,
        createReceipt: false,
      });
    }
  };

  const handlePlanChangeChange = (planChanges = {}) => {
    setPlanSettings({ ...planSettings, ...planChanges });
    if (planChanges?.customPlanId) {
      setCustomPlanDirty(true);
    }
  };

  const handleMileagePurposeUpdate = useCallback(
    async purpose => {
      if (purpose && currentCompany.mileagePurpose !== purpose && hasSystemAdminRole(authUser)) {
        await updateCompanyMileagePurpose(currentCompany._id, purpose);

        // Update currentCompany in store
        dispatch(saveCompany({ mileagePurpose: purpose }));
      }
    },
    [dispatch, authUser, currentCompany.mileagePurpose, currentCompany._id],
  );

  const handleFormSave = useCallback(async () => {
    closeCommuteConfirmModal();
    closeCustomPlanConfirmModal();
    setIsSubmitting(true);

    try {
      await handleMileagePurposeUpdate(currentCompanySettingValues.mileagePurpose);
    } catch (error) {
      setIsSubmitting(false);
      return handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorUpdatingMileagePurpose'),
        });
      });
    }

    const params = {};

    params.commute = formattedCompanyCommuteSettings;

    const ignoredValues = ['ach_enabled', 'mileagePurpose', 'billingMethod'];
    if (!hasSystemAdminRole(authUser)) {
      ignoredValues.push('stripe');
    }
    params.values = omit(currentCompanySettingValues, ignoredValues);

    if (isNull(params.values?.importLocationsMethod)) {
      delete params.values.importLocationsMethod;
    }

    if (hasSystemAdminRole(authUser)) {
      if (!params.values?.stripe?.invoiceDaysUntilDue) {
        setIsSubmitting(false);
        return Toast({
          type: 'error',
          message: t('invoiceDaysUntilDueMustBeHigher'),
        });
      }

      params.settings = Object.keys(currentCompanySettings).map(key => ({
        name: key,
        value: currentCompanySettings[key],
      }));

      if (currentCompanySettings.customPricePlan && planSettings.customPlanId) {
        params.planId = planSettings.customPlanId;
      } else if (!currentCompanySettings.customPricePlan && planSettings.standardPlanId) {
        params.pricePlan = planSettings.standardPlanId;
      }
    }

    try {
      const updatedSettings = await updateCompanySettings(companySettings._id, params);
      dispatch(updateCompanySettingsStore(updatedSettings));
      setIsInitialPlanCustom(!!planSettings.customPlanId);
      setIsDirty(false);
      setCustomPlanDirty(false);
      handleResetCommuteDirty();
      Toast({
        type: 'open',
        message: t('settingUpdated'),
      });
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorUpdatingCompanySettings'),
        });
      });
    }

    setIsSubmitting(false);
  }, [
    t,
    dispatch,
    authUser,
    companySettings._id,
    currentCompanySettingValues,
    planSettings,
    formattedCompanyCommuteSettings,
    currentCompanySettings,
    closeCommuteConfirmModal,
    closeCustomPlanConfirmModal,
    handleMileagePurposeUpdate,
    handleResetCommuteDirty,
  ]);

  return {
    // Boolean
    isSubmitting,
    isCustomPlanConfirmModalVisible,
    isCommuteConfirmModalVisible,
    isInitialPlanCustom,
    isDirty: isDirty || isCommuteSettingsDirty || isCustomPlanDirty,
    isCustomPlanDirty,
    isCommuteSettingsDirty,

    // General
    authUser,
    planSettings,
    currentCompany,
    currentCompanySettings,
    companyCommuteSettings,
    currentCompanySettingValues,

    isCommuteSettingCustom,
    isCommuteSettingSingleLocation,
    isCommuteSettingNotHaveOffice,
    isNoOfficeMaxDeduction,
    isHomeAddressMaxCommuteDeduction,
    isNoOfficeIgnoreFirstAndLast,
    commuteDistanceUnit,
    // Handlers
    handleFormSave,
    handleActiveFeatureChange,
    handleSettingValueChange,
    handleCommuteSettingChange,
    handlePlanChangeChange,

    openCustomPlanConfirmModal,
    openCommuteConfirmModal,
    closeCommuteConfirmModal,
    closeCustomPlanConfirmModal,
  };
};

export default useCompanySettingsState;
