import './style.scss';

import { Col, Divider, Form, Layout, Row } from 'antd';
import { INTERNAL_LINKS } from 'enum';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { isValidPassword } from 'utils/validate';

import { ERROR_MESSAGE, NAME_MAX_CHARACTER } from '../../utils/constants';
import { EMAIL_SPECIAL_CHAR_VALIDATION_REGEXP } from '../../utils/validate';
import Box from '../Box';
import Button from '../Button';
import NameInput from '../NameInput';
import RegistrationTerms from '../RegistrationTerms';
import Select from '../Select';
import Text from '../Text';
import FadedText from '../Text/FadedText';
import LinkText from '../Text/LinkText';
import TextInput from '../TextInput';

const { Content } = Layout;

const RegistrationForm = ({ companies, fieldValues, t, user, activating, ...rest }) => {
  const [form] = Form.useForm();

  // to store temporary company name on dropdown `add company`
  const [newCompany, setNewCompany] = useState('');

  // existing companies + temporary company
  const [allCompanies, setAllCompanies] = useState(companies);

  const passwordValidator = (rule, value) => {
    if (isValidPassword(value) > 0) {
      return Promise.reject(ERROR_MESSAGE().INVALID_PASSWORD);
    }
    if (value.length > NAME_MAX_CHARACTER) {
      return Promise.reject(t('maxPasswordLength'));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      });
    }
    // eslint-disable-next-line
  }, []);

  // Populate dropdown with existing companies from db
  useEffect(() => {
    setAllCompanies(companies);
  }, [companies]);

  const addTempCompany = () => {
    setAllCompanies([{ name: newCompany, _id: 'tempCompanyKey' }, ...allCompanies]);
  };

  const onCompanyNameChange = e => {
    setNewCompany(String(e.target.value));
  };

  const onSubmit = values => {
    fieldValues(values);
  };

  const onFinishFailed = values => {
    return;
  };

  return (
    <Row justify="center">
      <Col>
        <Box hoverable={false} className="content-wrapper">
          <Content>
            <Row className="kliks-row">
              <Text renderAs="p" variant="h2" textAlign="center" className="kliks-tagline">
                {t('mileageRbSimplified')}
              </Text>
            </Row>
            <Form
              form={form}
              name="registration"
              onFinish={onSubmit}
              onFinishFailed={onFinishFailed}
              initialValues={{ remember: true }}
            >
              <Row className="kliks-row form-subhead">
                <Text color="secondary" variant="b" size="large" textAlign="center">
                  {!!user ? t('Activate your account') : t('startYourFreeTrial')}
                </Text>
              </Row>

              {!user && (
                <Row className="kliks-row">
                  <FadedText
                    className="already-with-account"
                    textAlign="center"
                    variant="p"
                    size="xl"
                    renderAs="div"
                  >
                    {t(`alreadyHaveAccount?`)}
                    <NavLink to={INTERNAL_LINKS.LOGIN}>
                      <LinkText variant="p" size="xl" style={{ whiteSpace: 'nowrap' }}>
                        {t('logIn')}
                      </LinkText>
                    </NavLink>
                  </FadedText>
                </Row>
              )}

              <Row gutter={17}>
                <Col flex={1} className="reg-forms">
                  <NameInput size="small" name="firstName" disabled={activating} />
                </Col>
                <Col flex={1} className="reg-forms">
                  <NameInput size="small" name="lastName" disabled={activating} />
                </Col>
              </Row>
              <Row>
                <Form.Item
                  name="email"
                  className="kliks-row reg-forms"
                  validateTrigger={['onSubmit', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      message: ERROR_MESSAGE().BLANK_FIELD,
                    },
                    {
                      required: true,
                      type: 'email',
                      message: ERROR_MESSAGE().VALID_EMAIL,
                    },
                    () => ({
                      validator(rule, value = '') {
                        if (
                          /@{2,}/.test(value) ||
                          // eslint-disable-next-line no-useless-escape
                          EMAIL_SPECIAL_CHAR_VALIDATION_REGEXP.test(value.split('@')[0])
                        ) {
                          return Promise.reject(ERROR_MESSAGE().EMAIL_INVALID_CHARACTERS);
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <TextInput
                    size="small"
                    placeholder={t('enterValidWorkEmail')}
                    disabled={activating || !!user}
                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  className="kliks-row reg-forms"
                  name="password"
                  validateTrigger={['onSubmit', 'onBlur']}
                  help={<FadedText size="sm">{ERROR_MESSAGE().INVALID_PASSWORD}</FadedText>}
                  rules={[
                    { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
                    () => ({
                      validator(rule, value) {
                        return passwordValidator(rule, value);
                      },
                    }),
                  ]}
                >
                  <TextInput
                    disabled={activating}
                    maxLength={NAME_MAX_CHARACTER + 1}
                    password
                    size="small"
                    placeholder={t('Password')}
                    autoComplete="new-password"
                  />
                </Form.Item>
              </Row>

              {user && (
                <Row>
                  <Form.Item
                    className="kliks-row reg-forms"
                    name="password_repeat"
                    rules={[
                      { required: true, message: ERROR_MESSAGE().BLANK_FIELD },
                      () => ({
                        validator(rule, value) {
                          if (value !== form.getFieldValue('password')) {
                            return Promise.reject(ERROR_MESSAGE().PASSWORDS_DONT_MATCH);
                          }
                          return Promise.resolve();
                        },
                        validateTrigger: 'onBlur',
                      }),
                    ]}
                  >
                    <TextInput
                      disabled={activating}
                      maxLength={NAME_MAX_CHARACTER + 1}
                      password
                      size="small"
                      placeholder={t('confirmPassword')}
                      autoComplete="new-password"
                    />
                  </Form.Item>
                </Row>
              )}

              {!user && (
                <Row>
                  <Form.Item
                    className="kliks-row reg-forms"
                    name="companyName"
                    rules={[
                      {
                        required: true,
                        message: ERROR_MESSAGE().BLANK_FIELD,
                      },
                    ]}
                  >
                    {companies ? (
                      <Select
                        size="small"
                        showSearch
                        placeholder={t('selectCompanyOrOrganization')}
                        optionFilterProp="children"
                        options={
                          allCompanies.length
                            ? allCompanies.map(company => ({
                                value: company.name,
                                label: company.name,
                              }))
                            : []
                        }
                        dropdownRender={menu => (
                          <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                padding: 8,
                              }}
                            >
                              <TextInput
                                style={{
                                  flex: 'auto',
                                  marginRight: 10,
                                }}
                                onChange={e => onCompanyNameChange(e)}
                              />
                              <Button size="sm" type="primary" onClick={addTempCompany}>
                                {t('addCompany')}
                              </Button>
                            </div>
                          </div>
                        )}
                      />
                    ) : (
                      <TextInput
                        disabled={activating}
                        onChange={e => onCompanyNameChange(e)}
                        placeholder={t('enterCompanyName')}
                        size="small"
                      />
                    )}
                  </Form.Item>
                </Row>
              )}

              <Row>
                <RegistrationTerms isActivation={!!user} />
              </Row>

              <Row>
                <Form.Item className="kliks-row reg-forms button">
                  <Button
                    block
                    type={'primary'}
                    size="large"
                    className="start-trial-btn primary"
                    htmlType="submit"
                    disabled={activating}
                    loading={activating}
                  >
                    {user ? t('Activate') : t('startYourFreeTrial')}
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Content>
        </Box>
      </Col>
    </Row>
  );
};

RegistrationForm.propTypes = {
  companies: PropTypes.object,
  fieldValues: PropTypes.func,
  user: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default RegistrationForm;
