import './style.scss';

import { Col, Form, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { STATUS_LIST } from '../../enum';
import { APPROVAL_CONFIG_VALUES, RATE_TYPES } from '../../enum/Rates';
import useDebouncedState from '../../hooks/useDebouncedState';
import useRateForm from '../../hooks/useRateForm';
import { emitUpdateWizardDirty } from '../../stores/actions/rateWizard';
import { ERROR_MESSAGE } from '../../utils/constants';
import { isGovernmentFlatRate, isTieredRate } from '../../utils/rates';
import { selectStoreCompanySettings, useStoreSelector } from '../../utils/storeSelectors';
import FormItem from '../Form/FormItem';
import DataModal from '../Modal/DataModal';
import SectionStepWrapper from '../SectionStepWrapper';
import Select from '../Select';
import SpaceSpinner from '../SpaceSpinner';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import Switch from '../Switch';
import HorizontalSwitch from '../Switch/HorizontalSwitch';
import Text from '../Text';
import ApprovalSettingsFormSection from './ApprovalSettingsFormSection';
import GroupsFormSection from './GroupsFormSection';
import RateConfigurationFormSection from './RateConfigurationFormSection';
import RateInformationFormSection from './RateInformationFormSection';
import ReimbursementRateTypeFormSection from './ReimbursementRateTypeFormSection';
import TieredConfigurationFormSection from './TieredConfigurationFormSection';

/**
 * Product creation and edit form
 *
 * @param {object} props  Component props
 */
const ProductForm = props => {
  const {
    t,
    isEdit,
    formInstance,
    onCancel,
    onFinish,
    onCreateGroup,
    isCustomOrPremiumPlan,
  } = props;

  const dispatch = useDispatch();
  const rateWizard = useStoreSelector('rateWizard');
  const bands = rateWizard.rateValues.rateBands;
  const {
    name,
    groups,
    rateType,
    rateAmount,
    country,
    effectiveDate,
    approvalConfig,
    approvalSetting,
    defaultRate,
    proratedFixedAmount,
    prorationMethod,
  } = rateWizard.rateValues;

  const {
    companyGroups,
    currentDefaultRate,
    currentCountryRate,

    handleRateTypeChange,
    handleAddBand,
    handleRemoveLastBand,
    handleBandChange,
    handleFormValuesChange,
  } = useRateForm(formInstance);

  const handleNameChange = useCallback(name => handleFormValuesChange({ name }), [
    handleFormValuesChange,
  ]);

  const handleRateChange = useCallback(
    amount => {
      handleFormValuesChange({ rateAmount: amount });
    },
    [handleFormValuesChange],
  );

  const [, setDebouncedName] = useDebouncedState(name, 300, handleNameChange);
  const [, setRateAmount] = useDebouncedState(rateAmount, 300, handleRateChange);

  const { tripApprovalWorkflow } = useSelector(selectStoreCompanySettings);

  const hasFilledRates =
    rateType === RATE_TYPES.GOVT ||
    rateType === RATE_TYPES.FAVR ||
    (rateType === RATE_TYPES.FLAT && !!rateAmount);

  const hasFilledBands =
    rateType === RATE_TYPES.TIERED &&
    Array.isArray(bands) &&
    bands.length > 0 &&
    !!bands[0].bandEnd &&
    !!bands[0].bandRate;

  const stepOneCompleted = !!name && !!country;
  const stepTwoCompleted = stepOneCompleted && !!rateType;
  const stepThreeCompleted = stepTwoCompleted && (hasFilledRates || hasFilledBands);
  const stepFourCompleted = stepThreeCompleted && !!effectiveDate && !!approvalConfig;

  const [selectedApprovalDate, setSelectedApprovalDate] = useState();

  const handleApprovalDateChange = date => {
    handleFormValuesChange({ effectiveDate: date });
    setSelectedApprovalDate();
  };

  const groupOptions = useMemo(() => {
    return companyGroups.map(group => ({
      label: group.name,
      value: group._id,
    }));
  }, [companyGroups]);

  if (!currentCountryRate) {
    return <SpaceSpinner />;
  }

  return (
    <Form
      form={formInstance}
      labelCol={{ span: 24 }}
      className="product-form"
      onFinish={v => (stepFourCompleted ? onFinish(v) : undefined)}
      onValuesChange={() => {
        if (!rateWizard.isDirty) {
          dispatch(emitUpdateWizardDirty(true));
        }
      }}
    >
      <SectionStepWrapper
        step={1}
        showContent
        hideStepNumber={isEdit}
        title={!isEdit ? t('rateInformation') : ''}
        isCompleted={stepOneCompleted}
      >
        <RateInformationFormSection
          t={t}
          countryValue={country}
          isSubmitting={rateWizard.isSubmitting}
          onRateNameChange={e => setDebouncedName(e.target.value)}
          onCountryChange={countryValue => {
            handleFormValuesChange({ country: countryValue });
            handleRateTypeChange(undefined);
          }}
        />
      </SectionStepWrapper>

      <SectionStepWrapper
        step={2}
        hideStepNumber={isEdit}
        showContent={isEdit || stepOneCompleted}
        title={t('selectRateType')}
        isCompleted={stepTwoCompleted}
      >
        <ReimbursementRateTypeFormSection
          t={t}
          rateType={rateType}
          rateCountry={country}
          countryRate={currentCountryRate}
          isSubmitting={rateWizard.isSubmitting}
          onRateTypeChange={handleRateTypeChange}
        />

        {rateType === RATE_TYPES.FAVR && (
          <>
            <FormItem name="proratedFixedAmount" className="no-margin-bottom">
              <HorizontalSwitch
                label={t('prorateFixedAmount')}
                checked={proratedFixedAmount}
                onChange={prorated =>
                  handleFormValuesChange({
                    proratedFixedAmount: prorated,
                    prorationMethod: undefined,
                  })
                }
              />
            </FormItem>

            <FormItem
              name="prorationMethod"
              label={t('prorationMethod')}
              required={!!proratedFixedAmount}
              rules={[{ required: !!proratedFixedAmount, message: ERROR_MESSAGE().BLANK_FIELD }]}
            >
              <Select
                value={prorationMethod}
                disabled={!proratedFixedAmount}
                onChange={method => handleFormValuesChange({ prorationMethod: method })}
                options={[
                  {
                    label: t('calendarDays'),
                    value: 'calendar',
                  },
                  {
                    label: t('businessDays'),
                    value: 'business',
                  },
                ]}
              />
            </FormItem>
          </>
        )}
      </SectionStepWrapper>

      {isTieredRate(rateType, country) && (
        <SectionStepWrapper
          step={3}
          title={t('configureTieredRate')}
          isCompleted={stepThreeCompleted}
          hideStepNumber={isEdit}
          showContent={isEdit || stepTwoCompleted}
        >
          <TieredConfigurationFormSection
            t={t}
            bands={bands}
            formInstance={formInstance}
            isSubmitting={rateWizard.isSubmitting}
            rateCountry={country}
            rateType={rateType}
            onBandRemoval={handleRemoveLastBand}
            onBandAdd={handleAddBand}
            onBandEndChange={(val, i) => {
              handleBandChange(i, 'bandEnd', val);
              formInstance.setFieldsValue({ ['bandEnd-' + i]: val });
            }}
            onBandRateChange={(val, i) => {
              handleBandChange(i, 'bandRate', val);
              formInstance.setFieldsValue({ ['bandRate-' + i]: val });
            }}
          />
        </SectionStepWrapper>
      )}

      {(rateType === RATE_TYPES.FLAT || isGovernmentFlatRate(rateType, country)) && (
        <SectionStepWrapper
          step={3}
          title={t('selectConfigureFlatRate')}
          isCompleted={stepThreeCompleted}
          hideStepNumber={isEdit}
          showContent={isEdit || stepTwoCompleted}
        >
          <RateConfigurationFormSection
            t={t}
            isGovernmentFlatRate={isGovernmentFlatRate(rateType, country)}
            rate={rateAmount}
            Form
            countryRate={currentCountryRate}
            isSubmitting={rateWizard.isSubmitting}
            onRateChange={setRateAmount}
            onToggleEditRate={() => handleRateTypeChange(RATE_TYPES.FLAT)}
          />
        </SectionStepWrapper>
      )}

      <SectionStepWrapper
        step={rateType === RATE_TYPES.FAVR ? 3 : 4}
        title={t('selectEffectiveDateTripSettings')}
        isCompleted={stepFourCompleted}
        hideStepNumber={isEdit}
        showContent={isEdit || stepThreeCompleted}
      >
        <ApprovalSettingsFormSection
          t={t}
          isSubmitting={rateWizard.isSubmitting}
          tripApprovalWorkflow={tripApprovalWorkflow}
          rateCountry={country}
          fixedReceiptAutoApproval={rateWizard?.rateValues?.fixedReceiptAutoApproval}
          onRateChange={handleRateChange}
          approvalConfig={approvalConfig}
          approvalSetting={approvalSetting}
          onFormValueChange={handleFormValuesChange}
          onApprovalSettingChange={setting => handleFormValuesChange({ approvalSetting: setting })}
          onApprovalConfigChange={config => {
            handleFormValuesChange({
              approvalConfig: config,
              approvalType: undefined,
              approvalConfigValue: undefined,
            });
          }}
          approvalConfigOptions={APPROVAL_CONFIG_VALUES}
          effectiveDate={effectiveDate}
          onEffectiveDateChange={date => {
            if (
              isEdit &&
              rateWizard.rateValues.status === STATUS_LIST().Status.ACTIVE &&
              moment(date).isAfter(new Date())
            ) {
              setSelectedApprovalDate(date);
            } else {
              handleApprovalDateChange(date);
            }
          }}
        />

        <FormItem help={t('effectiveDateTrip_ifEnabled')}>
          {!defaultRate && currentDefaultRate && (
            <Row gutter={17} align="middle" style={{ height: 32 }}>
              <Col flex="170px">
                <Text variant="p" size="sm">
                  {t('currentDefaultRate')}
                </Text>
              </Col>

              <Col>
                <Text variant="strong" size="sm" renderAs="p">
                  {currentDefaultRate.name}
                </Text>
              </Col>
            </Row>
          )}

          <Row gutter={17} align="middle">
            <Col flex="170px">
              <FormItem
                name="defaultRate"
                label={t('makeThisDefaultRate?')}
                style={{ marginBottom: 0 }}
              />
            </Col>
            <Col>
              <Switch
                disabled={
                  rateWizard.isSubmitting ||
                  (defaultRate && currentDefaultRate?._id === rateWizard?.originalRateDetails?._id)
                }
                checked={defaultRate}
                onChange={checked => {
                  if (!rateWizard.isDirty) {
                    dispatch(emitUpdateWizardDirty(true));
                  }
                  handleFormValuesChange({ defaultRate: checked });
                }}
              >
                {t('defaultRate_ifChecked')}
              </Switch>
            </Col>
          </Row>
        </FormItem>
      </SectionStepWrapper>

      <SectionStepWrapper
        step={rateType === RATE_TYPES.FAVR ? 4 : 5}
        title={t('whichGroupsShouldUseRate?')}
        hideStepNumber={isEdit}
        showContent={isEdit || stepFourCompleted}
      >
        <GroupsFormSection
          t={t}
          isSubmitting={rateWizard.isSubmitting}
          companyGroups={companyGroups}
          currentRateName={name}
          groupsWithRates={rateWizard.groupsWithRates}
          groupOptions={groupOptions}
          groupValues={groups}
          onCreateGroup={onCreateGroup}
          onGroupChange={groupValues => {
            if (!rateWizard.isDirty) {
              dispatch(emitUpdateWizardDirty(true));
            }
            handleFormValuesChange({ groups: groupValues });
          }}
        />
      </SectionStepWrapper>

      <SubmitCancelButtonGroup
        isForm
        onCancel={onCancel}
        loading={rateWizard.isSubmitting}
        submitText={rateType === RATE_TYPES.FAVR || isCustomOrPremiumPlan ? t('Next') : t('Save')}
      />

      <DataModal
        visible={!!selectedApprovalDate}
        onCancel={() => {
          setSelectedApprovalDate();
          handleApprovalDateChange(effectiveDate);
        }}
        okText={t('yes')}
        onOk={() => handleApprovalDateChange(selectedApprovalDate)}
      >
        <Text variant="p" size="sm">
          <Trans
            t={t}
            i18nKey="changingEffectiveDateWill"
            components={[
              <Text variant="strong" size="sm">
                dummy
              </Text>,
            ]}
          />
        </Text>
        <br />
        <Text variant="p" size="sm">
          {t('sureWantToApplyChange')}
        </Text>
        <br />
      </DataModal>
    </Form>
  );
};

ProductForm.propTypes = {};

ProductForm.defaultProps = {
  initialValues: {},
};

export default ProductForm;
