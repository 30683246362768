import { CaretDownFilled } from '@ant-design/icons';
import { Col, Dropdown, notification, Row } from 'antd';
import { Button as CustomButton } from 'components';
import AddUserModal from 'components/AddUserModal';
import { INTERNAL_LINKS } from 'enum';
import { get } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { resendActivationEmail } from '../../api/auth';
import { handleApiCalls, handleApiErrors } from '../../api/axiosInstance';
import { updateGroupManager } from '../../api/group';
import { changeUserRole, changeUserStatus, USER_API } from '../../api/user';
import DownloadIcon from '../../components/DownloadIcon';
import ChangeMultipleGroupsManager from '../../components/Modal/ChangeMultipleGroupsManager';
import Switch from '../../components/Switch';
import UserManagerTable from '../../components/Table/UserManagerTable';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import Toast from '../../components/Toast';
import UserManagerTableActionsBox from '../../components/UserManagerTableActionsBox';
import PageContainer from '../../containers/PageContainer';
import { STATUS_LIST, USER_ROLES } from '../../enum';
import StatusList from '../../enum/StatusList';
import withAuthentication from '../../hocs/withAuthentication';
import usePaginatedAllCompanyUsersQuery from '../../hooks/queries/usePaginatedAllCompanyUsersQuery';
import useAdminsAndManagersUsersLists from '../../hooks/useAdminsAndManagersUsersLists';
import useDebouncedState from '../../hooks/useDebouncedState';
import useLocationSearchQueryParser from '../../hooks/useLocationSearchQueryParser';
import { updateProfile } from '../../stores/actions/profile';
import { checkIfStringContainsValue, formatPageTitle } from '../../utils/common';
import { replaceCurrentPageSearchQueryParams } from '../../utils/queryParams';
import {
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
  useStoreSelector,
} from '../../utils/storeSelectors';
import { splitUsersByRole } from '../../utils/users';
import { canSendActivationLink } from '../userdetails/profile/profile-permissions';
import classNames from './style.module.scss';
import { userImportCsvHeaders } from './user-csv-headers';

const ACTION_SET_ROLE = 'ACTION_SET_ROLE';
const ACTION_SET_STATUS = 'ACTION_SET_STATUS';
const ACTION_SET_GROUP = 'ACTION_SET_GROUP';

const UserManager = props => {
  const { t, history, location } = props;
  const initialSearchQuery = useLocationSearchQueryParser(location);

  const dispatch = useDispatch();
  const downloadBtnRef = useRef();
  const company = useSelector(selectStoreCurrentCompany);
  const authUser = useSelector(selectStoreCurrentAuthUser);
  const groupList = useStoreSelector('group.groupList', []);
  const companySettings = useStoreSelector('common.currentCompany.companySettingId');
  const [showOnlyActive, setShowOnlyActive] = useState(
    get(initialSearchQuery, 'showOnlyActive', true),
  );

  const {
    companyAdminsAndManagersListQuery,
    companyAdminsList,
    groupManagersLists,
  } = useAdminsAndManagersUsersLists();

  const [addUsersModalVisibility, setAddUsersModalVisibility] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [groupsToChangeList, setGroupsToChangeList] = useState([]);
  const [isResendingActivation, setIsResendingActivation] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [mainAction, setMainAction] = useState();
  const [selectUsers, setSelectUsers] = useState([]);
  const [subAction, setSubAction] = useState();
  const [searchTerm, setSearchTerm] = useDebouncedState('');
  const [updateMultipleGroupsModalVisible, setUpdateMultipleGroupsModalVisible] = useState(false);

  const {
    stringTableSort: allUsersSortString,
    allUsersTablePagination,
    allUsersQuery,
    allUsersHandleTableSort,
  } = usePaginatedAllCompanyUsersQuery(company?._id, {
    searchTerm,
    status: showOnlyActive ? [STATUS_LIST().Status.ACTIVE] : null,
  });

  const exportUsersToCSVMutation = useMutation(() =>
    USER_API.exportUsersToCSV(
      allUsersSortString,
      showOnlyActive ? [STATUS_LIST().Status.ACTIVE] : null,
    ),
  );

  const handleStatus = async active => {
    if (!selectUsers || selectUsers.length === 0) {
      Toast({
        type: 'error',
        message: t('chooseAtLeastOneUser'),
      });
      return;
    }

    setLoadingFlag(true);

    try {
      for (let index in selectUsers) {
        await handleUserActive(selectUsers[index]._id, active);
      }
    } catch (e) {
      console.error(e);
    }

    handleUpdateUserStore();
    setLoadingFlag(false);
  };

  const handleRoleChange = async role => {
    const userIDs = selectUsers.map(user => user._id);

    setLoadingFlag(true);

    try {
      await changeUserRole(role, userIDs);
      await allUsersQuery.refetch();

      if (userIDs.includes(authUser.profile._id)) {
        Toast({
          type: 'warning',
          message: t('removedOwnAdminPrivilege'),
        });

        dispatch(updateProfile({ ...authUser.profile, role }));
      }

      Toast({
        key: 'changing-role',
        type: 'open',
        message: t('changeRoleSuccess'),
      });
    } catch (error) {
      handleApiErrors(error.resposne, () => {
        Toast({
          type: 'error',
          message: t('changeRoleError'),
        });
      });
    }

    setLoadingFlag(false);
  };

  const handleChangeRoleToUser = async () => handleRoleChange(USER_ROLES.USER);
  const handleChangeRoleToManager = async () => handleRoleChange(USER_ROLES.COMPANY_MANAGER);
  const handleChangeRoleToAdmin = async () => handleRoleChange(USER_ROLES.COMPANY_ADMIN);

  const handleCheckRoleParam = () => {
    const groupsAffectedByRoleChanges = [];

    const {
      usersWithAdminRole: selectedUsersWithAdminRole,
      usersWithManagerRole: selectedUsersWithManagerRole,
      usersWithUserRole: selectedUsersWithUserRole,
    } = splitUsersByRole(selectUsers);

    const selectedUserIDs = selectUsers.map(u => u._id);
    const ADMINS_AFTER_CHANGE = companyAdminsList.filter(u => !selectedUserIDs.includes(u._id));

    if (
      (companyAdminsList.length === 1 && !!selectedUsersWithAdminRole.length) ||
      !ADMINS_AFTER_CHANGE.length
    ) {
      Toast({
        duration: 10, // seconds
        type: 'error',
        message: t('companyRequiresCompanyAdmin'),
      });

      return false;
    }

    switch (subAction) {
      // Handle side effects of Role changing to USER
      case USER_ROLES.USER: {
        const selectedUserIDsWithGroups = [
          ...selectedUsersWithAdminRole,
          ...selectedUsersWithManagerRole,
        ].map(u => !!u.group && u._id);

        groupList.forEach(group => {
          // TODO: handle additionalManagers too
          if (selectedUserIDsWithGroups.includes(group.groupManager?._id)) {
            groupsAffectedByRoleChanges.push(group);
          }
        });

        if (selectedUsersWithUserRole.length) {
          Toast({
            type: 'error',
            message: t('selectedUsersHaveRole'),
          });
          return false;
        }

        if (!!groupsAffectedByRoleChanges.length) {
          setUpdateMultipleGroupsModalVisible(true);
          setGroupsToChangeList(groupsAffectedByRoleChanges);
        } else {
          handleChangeRoleToUser();
        }
        break;
      }
      case USER_ROLES.COMPANY_MANAGER: {
        handleChangeRoleToManager();
        break;
      }
      case USER_ROLES.COMPANY_ADMIN: {
        handleChangeRoleToAdmin();
        break;
      }
      default: {
        break;
      }
    }

    return true;
  };

  const handleRole = async role => {
    if (!selectUsers || selectUsers.filter(Boolean).length === 0) {
      return Toast({
        type: 'error',
        message: t('chooseAtLeastOneUser'),
      });
    }

    handleCheckRoleParam(role);
  };

  /**
   * Resend the Activation email to a given user
   *
   * @param {string} email Email of User
   */
  const handleResendActivationEmail = async email => {
    if (!isResendingActivation) {
      setIsResendingActivation(true);
      await resendActivationEmail(email, () => {
        setIsResendingActivation(false);
        Toast({
          type: 'open',
          message: t('activationEmailSent'),
        });
      });
    }
  };

  const handleUserActive = async (userId, status) => {
    const toastKey = 'activationEmail';

    if ([StatusList(t).Status.ACTIVE].includes(status)) {
      const userToChange = allUsersQuery.data.users.find(user => user._id === userId);
      if ([StatusList(t).Status.PENDING].includes(userToChange.status)) {
        return Toast({
          key: toastKey,
          type: 'error',
          message: (
            <>
              <span>{t('cannotActivatePendingUser')}</span>{' '}
              {canSendActivationLink(authUser, userToChange) && (
                <span>
                  <Trans
                    t={t}
                    i18nKey="resendActivationEmail?"
                    components={
                      <LinkText
                        variant="b"
                        onClick={() => {
                          notification.close(toastKey);
                          handleResendActivationEmail(userToChange.email);
                        }}
                      >
                        dummy
                      </LinkText>
                    }
                  />
                </span>
              )}
            </>
          ),
        });
      }
    }

    try {
      await changeUserStatus(userId, status);
    } catch (e) {
      handleApiErrors(e.response, () => {
        Toast({
          type: 'error',
          message: t('changeRoleError'),
        });
      });
    }
  };

  const handleSelectUser = (selectedRowKeys, selectedRows) => {
    setSelectUsers(selectedRows);

    if (!selectedRows.length) {
      setMainAction();
      setSubAction();
    }
  };

  const handleMainAction = value => {
    if (mainAction !== value) {
      setMainAction(value);
      setSubAction();
    }
  };

  const handleUserAssignGroup = async (userId, groupId) => {
    try {
      let params = {
        userId: userId,
      };
      let result = await handleApiCalls(
        'patch',
        `${process.env.REACT_APP_HOST_API}group/${groupId}/users/link`,
        params,
      );
      if (result && result.data && result.data.data && result.data.data.userData) {
        handleUpdateUserStore();
      }
    } catch (e) {
      console.log('link error', e);
    }
  };

  const handleUpdateUserStore = () => {
    allUsersQuery.refetch();
    companyAdminsAndManagersListQuery.refetch();
  };

  const handleUserUnassignGroup = async (userId, groupId) => {
    try {
      let params = {
        userId: userId,
      };
      await handleApiCalls(
        'patch',
        `${process.env.REACT_APP_HOST_API}group/${groupId}/users/unlink`,
        params,
      );
      return;
    } catch (e) {
      console.log('link error', e);
      return;
    }
  };

  const handleLink = async () => {
    let selectGroup = subAction;
    if (!selectUsers || selectUsers.length === 0) {
      Toast({
        type: 'error',
        message: t('chooseAtLeastOneUser'),
      });
      return;
    }

    if (!selectGroup && selectGroup === '') {
      Toast({
        type: 'error',
        message: t('pleaseSelectGroup'),
      });
      return;
    }

    setLoadingFlag(true);

    try {
      for (let index in selectUsers) {
        if (selectUsers[index]?._id && selectUsers[index]?.group?._id) {
          await handleUserUnassignGroup(selectUsers[index]._id, selectUsers[index].group._id);
        }
        await handleUserAssignGroup(selectUsers[index]._id, selectGroup);
      }

      await allUsersQuery.refetch();
    } catch (e) {
      console.error(e);
    }

    setLoadingFlag(false);
  };

  const handleSave = async () => {
    const { role } = authUser.profile || {};
    if ([USER_ROLES.COMPANY_ADMIN, USER_ROLES.SYSTEM_ADMIN].includes(role)) {
      try {
        if (mainAction === ACTION_SET_GROUP) {
          await handleLink();
        } else if (mainAction === ACTION_SET_STATUS) {
          await handleStatus(subAction);
        } else if (mainAction === ACTION_SET_ROLE) {
          await handleRole(subAction);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      Toast({
        type: 'error',
        message: t('notAllowedToChangeOthersRoles'),
      });
    }
  };

  const filteredData = useMemo(() => {
    if (!searchTerm) return allUsersQuery.data.users;

    let filteredRaw = allUsersQuery.data.users.filter(userItem => {
      let userName = userItem.firstName + ' ' + userItem.lastName;
      return (
        // Matches names
        checkIfStringContainsValue(userName, searchTerm) ||
        // Matches email
        checkIfStringContainsValue(userItem.email, searchTerm) ||
        // Matches role
        checkIfStringContainsValue(userItem.role, searchTerm) ||
        // Matches status
        checkIfStringContainsValue(userItem.status, searchTerm) ||
        // Matches Group
        checkIfStringContainsValue(get(userItem, 'group.name', ''), searchTerm)
      );
    });

    return filteredRaw;
  }, [searchTerm, allUsersQuery]);

  const onExportToCSV = async () => {
    try {
      if (!selectUsers || selectUsers.length === 0) {
        Toast({
          type: 'error',
          message: t('chooseAtLeastOneUser'),
        });
        return false;
      }
      setLoadingFlag(true);
      let ids = selectUsers.map(user => user._id);

      const url = `${process.env.REACT_APP_HOST_API}user/get-download-data`;
      let result = await handleApiCalls('post', url, { ids: ids });
      setLoadingFlag(false);
      if (result.status === 200) {
        setDownloadData(result.data.data);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      setLoadingFlag(false);
      return false;
    }
  };

  const handleDownload = async () => {
    let result = await onExportToCSV();
    if (result) {
      downloadBtnRef.current.link.click();

      Toast({
        type: 'info',
        message: t('downloadStarted'),
      });
    }
  };

  const handleShowOnlyActiveChange = useCallback(
    active => {
      setShowOnlyActive(active);
      replaceCurrentPageSearchQueryParams(history, { showOnlyActive: active });
    },
    [history],
  );

  return (
    <PageContainer
      title={'Users'}
      className={classNames.userManager}
      sideActionComponent={
        <div
          className="custom-button-group"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <CustomButton
            className="invite-button"
            onClick={() => setAddUsersModalVisibility(true)}
            type="secondary"
            size="large"
          >
            + {t('inviteUser')}
          </CustomButton>
          <Dropdown
            className="invite-button"
            placement="bottomRight"
            menu={{
              items: [
                {
                  key: 'upload-list',
                  label: t('uploadList'),
                  onClick: () => history.push(INTERNAL_LINKS.BULK_INVITE),
                },
                {
                  key: 'invite-multiple',
                  label: t('inviteMultipleUsers'),
                  onClick: () =>
                    history.push({
                      pathname: INTERNAL_LINKS.INVITE_USERS,
                      state: { groups: groupList },
                    }),
                },
              ],
            }}
            type="secondary"
            size="large"
            style={{ padding: '5px' }}
          >
            <CaretDownFilled
              style={{
                boxShadow: '0 0 2px rgba(0, 0, 33, 0.14), 0 1px 3px rgba(0, 18, 71, 0.2)',
                alignSelf: 'center',
                padding: '17px 5px',
                background: 'white',
              }}
            />
          </Dropdown>
        </div>
      }
    >
      <Helmet>
        <title>{formatPageTitle('Manage Users')}</title>
      </Helmet>

      <div className={classNames.userManagerContainer}>
        <Row justify="end" align="middle" gutter={[8, 8]}>
          <Col>
            <Row align="middle" gutter={[8, 8]} wrap={false}>
              <Col>
                <Text size="sm">{t('showActiveOnly')}</Text>
              </Col>
              <Col>
                <Switch
                  size="medium"
                  defaultChecked={showOnlyActive}
                  onChange={handleShowOnlyActiveChange}
                />
              </Col>
            </Row>
          </Col>

          <DownloadIcon
            t={t}
            colWidth="75px"
            text={t('exportToCSV')}
            loading={exportUsersToCSVMutation.isLoading}
            onClick={exportUsersToCSVMutation.mutateAsync}
            disabled={
              !allUsersTablePagination.paginationConfig.total ||
              allUsersTablePagination.paginationConfig.total === 0
            }
          />
        </Row>

        <div className={classNames.actionsBoxWrapper}>
          <UserManagerTableActionsBox
            t={t}
            groupList={groupList}
            actionValue={mainAction}
            subActionValue={subAction}
            displayBulkActions={Array.isArray(selectUsers) && !!selectUsers.length}
            onSearch={e => {
              allUsersTablePagination.handlePageChange(1);
              setSearchTerm(e.target.value);
            }}
            onActionSelect={handleMainAction}
            onSubActionSelect={setSubAction}
            onDownload={handleDownload}
            onSave={handleSave}
            disabled={loadingFlag}
          />
        </div>

        <div className={classNames.userManagerContainerTable}>
          <UserManagerTable
            t={t}
            asyncSort
            loading={loadingFlag || allUsersQuery.isFetching}
            onRowSelect={(selectedRowKeys, selectedRows) => {
              handleSelectUser(selectedRowKeys, selectedRows);
            }}
            dataSource={filteredData}
            onChange={({ current }, filters, sorters) => {
              allUsersTablePagination.handlePageChange(current);
              allUsersHandleTableSort(sorters?.columnKey, sorters?.order);
            }}
            pagination={{
              pageSize: allUsersTablePagination.paginationConfig.pageSize,
              total: allUsersTablePagination.paginationConfig.total,
              current: allUsersTablePagination.paginationConfig.current,
              onShowSizeChange: allUsersTablePagination.handlePageSizeChange,
            }}
          />
        </div>
      </div>

      <ChangeMultipleGroupsManager
        t={t}
        visible={updateMultipleGroupsModalVisible}
        onGroupManagerChange={updateGroupManager}
        onFinish={handleChangeRoleToUser}
        onCancel={() => {
          setUpdateMultipleGroupsModalVisible(true, () => {
            // Reset values after a delay to avoid displaying an empty modal while closing
            setTimeout(() => {
              setGroupsToChangeList([]);
            }, 200);
          });
        }}
        groupsToChangeList={groupsToChangeList}
        managersList={groupManagersLists.filter(user => {
          const selectedUserIds = selectUsers.map(u => u._id);
          return !selectedUserIds.includes(user._id);
        })}
      />

      <CSVLink
        ref={downloadBtnRef}
        className={classNames.userManagerFooterSelectRightExportToCsv}
        target="_self"
        filename="users.csv"
        data={downloadData}
        headers={userImportCsvHeaders}
        visible="false"
      >
        {t('downloadUsers')}
      </CSVLink>

      {addUsersModalVisibility && (
        <AddUserModal
          companyId={company && company._id}
          groups={groupList}
          modalVisibility={addUsersModalVisibility}
          visible={setAddUsersModalVisibility}
          requireEmployeeID={companySettings?.settings?.requireEmployeeNumber}
          onSuccess={handleUpdateUserStore}
        />
      )}
    </PageContainer>
  );
};

UserManager.propTypes = {};

UserManager.defaultProps = {};

export default withNamespaces()(withAuthentication(UserManager));
