import { Col, Row } from 'antd';
import { capitalize, get, isUndefined } from 'lodash';
import React from 'react';

import { IMAGES, INTERNAL_LINKS, STATUS_LIST } from '../../../enum';
import { momentCompanyFormat } from '../../../utils/common';
import { convertMillisecondsIntoReadableTime } from '../../../utils/datetime';
import { formatNumberToLocale, formatNumberWithCurrency } from '../../../utils/numbers';
import { selectStoreCompanyGroupByID } from '../../../utils/storeSelectors';
import { hasTripsWithDeductedCommuteMiles } from '../../../utils/trips';
import { canDeleteReceipt } from '../../../views/receipt/receipt-permissions';
import Button from '../../Button';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';
import Tooltip from '../../Tooltip';
import CustomTable from '../index';
import classNames from './styles.module.scss';

const renderInsightContent = (content, value, formattedValue, visible) => {
  return (
    <Row gutter={10} justify="end" align="middle" className={classNames.insightRow}>
      <Col>
        <Text size="sm">{content}</Text>
      </Col>

      {visible && !isUndefined(value) && (
        <Col className={classNames.insightColumn}>
          <Row align="middle">
            <Col>
              <img
                alt="Trend arrow"
                src={parseFloat(value) >= 0 ? IMAGES.ARROW_POINT_UP : IMAGES.ARROW_POINT_DOWN}
              />
            </Col>
            <Col flex={70}>
              <Text size="sm" color={parseFloat(value) >= 0 ? 'primary' : 'red'}>
                {formattedValue}
              </Text>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

const DATE_FORMAT = 'MM/DD/YYYY';

const ReimbursementsWithInsightsTable = props => {
  const {
    t,
    dataSource,
    loading,
    isInsightsEnabled,
    onViewInsights,
    onDeleteReceipt,
    ...rest
  } = props;

  const COLUMNS = [
    {
      width: 150,
      title: t('name/group'),
      key: ['submittedBy.lastName', 'submittedBy.firstName'].toString(),
      sorter: true,
      render: (actions, data) => (
        <ProfileNamePicture
          t={t}
          redirectTo={{
            pathname: `${INTERNAL_LINKS.REIMBURSEMENT}/${data._id}`,
            state: { goReimbursementsQueryParams: window.location.search },
          }}
          complianceStatus={get(data, 'complianceStatus')}
          user={{
            _id: data.submittedBy?._id,
            firstName: data.submittedBy?.firstName || '-',
            lastName: data.submittedBy?.lastName,
            isAnonymized: data.submittedBy?.isAnonymized,
            profilePicture: data.submittedBy?.profilePicture,
            status: data.submittedBy?.status,
            group: selectStoreCompanyGroupByID(data.submittedBy?.group),
          }}
        />
      ),
    },
    {
      width: 150,
      defaultSortOrder: 'descend',
      title: t('createdDate'),
      key: 'created',
      dataIndex: 'created',
      render: date => momentCompanyFormat(date, DATE_FORMAT),
      sorter: true,
    },
    {
      width: 210,
      key: 'startOfPeriod',
      title: t('datePeriod'),
      sorter: true,
      render: (actions, data) => {
        if (data.endOfPeriod && data.startOfPeriod) {
          const periodStart = momentCompanyFormat(data.startOfPeriod, DATE_FORMAT);
          const periodEnd = momentCompanyFormat(data.endOfPeriod, DATE_FORMAT);
          return (
            <>
              <Row justify="center">
                <Col>
                  <Text size="sm">{`${periodStart} - ${periodEnd}`}</Text>
                </Col>
              </Row>
              {data.paymentStatus === STATUS_LIST().Status.OVERDUE && (
                <Row style={{ marginTop: 5 }}>
                  <Col flex={1}>
                    <StatusTag fullWidth status={STATUS_LIST().Status.OVERDUE} />
                  </Col>
                </Row>
              )}
            </>
          );
        }

        return '-';
      },
    },
    {
      width: 140,
      align: 'right',
      key: 'crmPercentage',
      title: t('crmPercent'),
      render: (actions, data) =>
        renderInsightContent(
          `${typeof data.crmPercentage === 'undefined' ? 0 : data.crmPercentage}%`,
          data.crmPercentInsight,
          `${formatNumberToLocale(Math.abs(data.crmPercentInsight), 0)}%`,
          isInsightsEnabled,
        ),
    },
    {
      width: 170,
      align: 'right',
      key: 'stopDuration',
      title: t('stopDurationAvg'),
      render: (actions, data) =>
        renderInsightContent(
          convertMillisecondsIntoReadableTime(data.stopDuration),
          data.stopDurationAverageInsight,
          `${formatNumberToLocale(Math.abs(data.stopDurationAverageInsight), 0)}%`,
          isInsightsEnabled,
        ),
    },
    {
      align: 'right',
      title: t('totalDistance'),
      key: 'totalDistance',
      dataIndex: 'totalDistance',
      sorter: true,
      render: (totalDistance, data) => {
        return renderInsightContent(
          totalDistance,
          data.totalDistanceInsight,
          `${formatNumberToLocale(Math.abs(data.totalDistanceInsight), 0)}%`,
          isInsightsEnabled,
        );
      },
    },
    {
      align: 'right',
      title: t('totalAmount'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: true,
      render: (totalAmount, data) => {
        return renderInsightContent(
          formatNumberWithCurrency(totalAmount, data.currency),
          data.totalAmountInsight,
          `${formatNumberToLocale(Math.abs(data.totalAmountInsight), 0)}%`,
          isInsightsEnabled,
        );
      },
    },
    {
      align: 'right',
      key: 'gpsVerifiedPercentage',
      title: t('gpsVerified'),
      render: (actions, data) =>
        `${typeof data.gpsVerifiedPercentage === 'number' ? data.gpsVerifiedPercentage : 0}%`,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: status => (
        <StatusTag
          status={
            status === STATUS_LIST().Status.PENDING
              ? STATUS_LIST().Status.AWAITING_APPROVAL
              : status
          }
        />
      ),
    },
    {
      width: 100,
      title: t('type'),
      dataIndex: 'type',
      key: 'type',
      render: type => capitalize(type),
      sorter: true,
    },
    {
      width: 100,
      title: '',
      render: reimbursement => {
        return (
          <Row>
            {typeof onDeleteReceipt === 'function' && (
              <Col>
                <Tooltip
                  placement="left"
                  enabled={!canDeleteReceipt(reimbursement)}
                  title={t('onlyFixedReimbursementsCanBeDeleted')}
                >
                  <Button
                    size="sm"
                    type="secondary"
                    disabled={!canDeleteReceipt(reimbursement)}
                    onClick={() => onDeleteReceipt(get(reimbursement, '_id'))}
                  >
                    {t('Delete')}
                  </Button>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  return (
    <div>
      <Row justify="end" align="middle" gutter={16} className={classNames.tableTopRow}>
        <Col>
          <Tooltip title={t('kliksInsights')}>
            <img
              width="25px"
              className={classNames.insightToggleSvg}
              src={
                isInsightsEnabled ? IMAGES.GREEN_REPORT_GRAPH_ICON : IMAGES.DARK_REPORT_GRAPH_ICON
              }
              alt="Insight toggle"
              onClick={loading ? undefined : onViewInsights}
            />
          </Tooltip>
        </Col>
      </Row>

      <Row>
        <CustomTable
          showSearchInput={false}
          loading={loading}
          {...rest}
          dataSource={dataSource || []}
          rowKey={record => record._id}
          className="reimbursements-table"
          columns={COLUMNS}
          scroll={{ x: 1300 }}
          onRow={row => ({
            leftBorderColor: hasTripsWithDeductedCommuteMiles([row.trips]) ? 'danger' : '',
          })}
        />
      </Row>
    </div>
  );
};

export default ReimbursementsWithInsightsTable;
